import {
  Card,
  FormControl,
  Grid,
  MenuItem,
  TextField,
  Select,
  InputLabel,
  Button,
  CircularProgress,
  IconButton,
  Tooltip, Popper, Box, Input
} from "@material-ui/core";
import React, { useState, useEffect } from "react";
import Refresh from "@material-ui/icons/Refresh";
import { useStyles } from "./muistyles";
//import Autocomplete from '@material-ui/lab/Autocomplete'
const options = ['100', '200']
function MuiForm({ ...props }) {
  const classes = useStyles();
  const [isLoading, setIsloading] = useState(false);
  const [suggestions, setSuggestions] = useState([])
  const {
    date,
    vcf,
    fan_num,
    bdc,
    omc,
    volumeObs,
    volumeAt20,
    product_type,
    handleInput,
    handleSubmit,
    handleSelect,
    ApiCalculateVcf,
    brv_num,
    //  vcfCalc,
    loading,

  } = props;
//console.log(brv_num, " ****");
  useEffect(() => {
    //SET & fetch SUGGESTIONS
    let suggestions = localStorage.getItem("suggestions");
    if (!suggestions) {
      let sugg = [];

      localStorage.setItem("suggestions", JSON.stringify(sugg));
    }
    let suggObj = JSON.parse(localStorage.getItem("suggestions"));
    setSuggestions(suggObj)
  }, [])


  const onHandleInput = (e) => {
    //   console.log(e.target.name, e.target.value)
    return handleInput(e.target.name, e.target.value);
  };
  const onHandleSelect = (e) => {
    return handleSelect(e.target.name, e.target.value);
  };
  const onHandleSubmit = (e) => {
    e.preventDefault();
    return handleSubmit();
  };
  const onHandleSuggest = (e) => {
    const value = e.target.value
    let sugg = []
    let suggList = JSON.parse(localStorage.getItem("suggestions"));
    if (typeof suggList === "object" && suggList) {

      //first check if value exists
      sugg = suggList

      const isExists = (list, val) => {
        if (typeof list === "object" && !list.includes(val)) {
          if (val !== "") {
            sugg.push(val);
          }

        }

        return list;
      };

      isExists(suggList, value);

      //sugg.sugg_sealer.push('gfg')

      localStorage.setItem("suggestions", JSON.stringify(sugg));
      setSuggestions([...suggestions, value])
    } else {
      //set initial values
      localStorage.setItem("suggestions", JSON.stringify(sugg));
    }
  };


  const handleReload = () => {
    setIsloading(true);
    window.location.reload();
  };
  return (
    <>
      <Card className={classes.EntryFormCard} variant="outlined">
        <form onSubmit={onHandleSubmit} autoComplete='on'>
          <Grid container className={classes.EntryFormContainer} spacing={3}>
            <Grid item xs={4}>
              <TextField
                type="text"
                InputLabelProps={{
                  shrink: true,
                }}
                variant="outlined"
                name="date"
                label="DATE"
                value={date ? date : ''}
                inputProps={{ readOnly: true }}
                className={classes.TextField}

              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                InputLabelProps={{
                  shrink: true,
                }}
                name="fan_num"
                variant="outlined"
                type="text"
                value={fan_num}
                label="TRANSACTION #"
                onChange={onHandleInput}
                inputProps={{ list: 'suggestions_transaction' }}
                onBlur={onHandleSuggest}
              />
              <datalist id='suggestions_transaction'>

                {
                  suggestions && suggestions.map((option, i) => (
                    <option key={i} value={option}> {option}</option>
                  ))
                }
              </datalist>
            </Grid>
            <Grid item xs={4}>
              
             <SelectBox
                field="BRV #"
                data={props.brvs}
                name="brv_num"
                value={brv_num}
                onChange={onHandleSelect}
               
              />
            </Grid>
            <Grid item xs={4}>
              <SelectBox
                field="BDC"
                data={props.bdcs}
                name="bdc"
                value={bdc}
                onChange={onHandleSelect}
               
              />
            </Grid>
            <Grid item xs={4}>
              <SelectBox1
                field="PRODUCT TYPE"
                data={["AGO", "PMS", "PMS-95"]}
                name="product_type"
                value={product_type}
                onChange={onHandleSelect}
               
              />
            </Grid>
            <Grid item xs={4}>
              <SelectBox
                field="OMC"
                data={props.omcs}
                name="omc"
                value={omc}
                onChange={onHandleSelect}
               
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                InputLabelProps={{
                  shrink: true,
                }}
                variant="outlined"
                type="text"
                label="DESTINATION"
                name="destination"
                onChange={onHandleInput}
                inputProps={{ list: 'sugg_destination' }}
                onBlur={onHandleSuggest}
              />
              <datalist id='sugg_destination'>

                {
                  suggestions && suggestions.map((option, i) => (
                    <option key={i} value={option}> {option}</option>
                  ))
                }
              </datalist>
            </Grid>

            <Grid item xs={4} style={{ display: 'block' }}>
              <TextField
                InputLabelProps={{
                  shrink: true,
                }}
                variant="outlined"
                type="text"
                name="litre_temp"
                label="L / TEMPERATURE (°C)"
                onChange={onHandleInput}
              

              />

            </Grid>
           
            <Grid item xs={4}>
              <TextField
                InputLabelProps={{
                  shrink: true,
                }}
                variant="outlined"
                type="text"
                name="litre_density_at_20"
                label="L / DENSITY@20 (g/ml)"
                onChange={onHandleInput}
                //  onBlur={ApiCalculateVcf}
                inputProps={{ list: 'density' }}
                onBlur={onHandleSuggest}
              />
              <datalist id='density'>
                {
                  suggestions && suggestions.map((option, i) => (
                    <option key={i} value={option}> {option}</option>
                  ))
                }
              </datalist>
            </Grid>
            <Grid item xs={4} style={{ display: "flex", flexWrap: "nowrap", display: 'none' }}>
              <TextField
                InputLabelProps={{
                  shrink: true,
                }}
                variant="outlined"
                type="text"
                label="VCF"
                name="vcf"
                value={vcf}
                onChange={onHandleInput}
                inputProps={{ readOnly: true }}
              />{" "}
              <Button
                type="button"
                variant="contained"
                onClick={ApiCalculateVcf}
                disabled={loading}
                style={{
                  marginLeft: 5,
                  textAlign: "center",
                  height: "100%",
                  marginBottom: 5,
                  textTransform: "none",
                  backgroundColor: "#ffe4c452",
                  boxShadow: "none",
                  border: "1px solid #f3b871",
                  borderRadius: 3,
                }}
              >
                {loading ? (
                  <CircularProgress
                    color="primary"
                    style={{
                      position: "absolute",
                      height: "1.6rem",
                      width: "1.6rem",
                      color: "#f3b871",
                    }}
                  />
                ) : (
                  <CalculatorIcon />
                )}
              </Button>
            </Grid>
            <Grid item xs={4} style={{ display: 'block' }}>
              <TextField
                InputLabelProps={{
                  shrink: true,
                }}
                variant="outlined"
                type="text"
                name="releasing_tank"
                label="RELEASING TANK"
               
                onChange={onHandleInput}
              />

            </Grid>
            <Grid item xs={4}>
              <TextField
                InputLabelProps={{
                  shrink: true,
                }}
                variant="outlined"
                type="text"
                label="DRIVER"
                name="driver"
                onChange={onHandleInput}
                inputProps={{ list: 'driver' }}
                onBlur={onHandleSuggest}
              />
              <datalist id='driver'>
                {
                  suggestions && suggestions.map((option, i) => (
                    <option key={i} value={option}> {option}</option>
                  ))
                }
              </datalist>
            </Grid>
            <Grid item xs={4}>
              <TextField
                InputLabelProps={{
                  shrink: true,
                }}
                variant="outlined"
                type="text"
                label="ISSUER"
                name="issuer"
                onChange={onHandleInput}
                inputProps={{ list: 'sugg_issuer' }}
                onBlur={onHandleSuggest}
              />
              <datalist id='sugg_issuer'>
                {
                  suggestions && suggestions.map((option, i) => (
                    <option key={i} value={option}> {option}</option>
                  ))
                }
              </datalist>
            </Grid>
            <Grid item xs={4} style={{display:'block'}}>
              <TextField
                InputLabelProps={{
                  shrink: true,
                }}
                variant="outlined"
                type="text"
                label="VOLUME OBS."
                name="total"
                // value={volumeObs}
                onChange={onHandleInput}
                inputProps={{ list: 'volume_obs_sugg' }}
                onBlur={onHandleSuggest}
              />
              <datalist id='volume_obs_sugg'>
                {
                  suggestions && suggestions.map((option, i) => (
                    <option key={i} value={option}> {option}</option>
                  ))
                }
              </datalist>
            </Grid>
            <Grid item xs={4}>
              <TextField
                InputLabelProps={{
                  shrink: true,
                }}
                variant="outlined"
                type="text"
                label="VOLUME@20"
                name="volumeAt20"
                value={volumeAt20}
                inputProps={{ readOnly: true }}

              />
            </Grid>
            <Grid item xs={4} style={{ display: 'block' }}>
              <TextField
                InputLabelProps={{
                  shrink: true,
                }}
                variant="outlined"
                type="text"
                name="rack_num"
                label="RACK #"

                onChange={onHandleInput}
              />

            </Grid>
            <Grid item xs={4} style={{ display: 'block' }}>
              <TextField
                InputLabelProps={{
                  shrink: true,
                }}
                variant="outlined"
                type="text"
                name="loader"
                label="LOADER"
                onChange={onHandleInput}
              />

            </Grid>
          <Grid item xs={4} style={{display:'none'}}></Grid> 
            <Grid
              item
              xs={4}
              style={{ display: "flex", justifyContent: "flex-end" }}
            >
              <Tooltip title="Refresh form">
                <IconButton onClick={handleReload} >
                  {isLoading ? (
                    <div></div>
                  ) : (
                    <Refresh style={{ color: "#aaa" }} />
                  )}
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>
        </form>
      </Card>
    </>
  );

  function CalculatorIcon() {
    return (
      <svg
        style={{ fontSize: "2rem", color: "#f3b871" }}
        className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium MuiBox-root css-uqopch"
        focusable="false"
        viewBox="0 0 24 24"
        aria-hidden="true"
        data-testid="CalculateIcon"
      >
        <path d="M19 3H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm-5.97 4.06L14.09 6l1.41 1.41L16.91 6l1.06 1.06-1.41 1.41 1.41 1.41-1.06 1.06-1.41-1.4-1.41 1.41-1.06-1.06 1.41-1.41-1.41-1.42zm-6.78.66h5v1.5h-5v-1.5zM11.5 16h-2v2H8v-2H6v-1.5h2v-2h1.5v2h2V16zm6.5 1.25h-5v-1.5h5v1.5zm0-2.5h-5v-1.5h5v1.5z"></path>
      </svg>
    );
  }



  function SelectBox1({ name, field, data, handleSelect, value, ...rest }) {
    return (
      <>
        <FormControl
          style={{ width: "100%", fontSize: ".8rem" }}
          fullWidth
          variant="filled"
        >
          <InputLabel id="demo-simple-select-helper-label"> {field}</InputLabel>
          <Select
            style={{ width: "100%" }}
            labelId="demo-simple-select-helper-label"
            id="demo-simple-select-helper"
            defaultValue=""
            value={value}
            name={name}
            label={field}

            {...rest}
          >
            <MenuItem value="">
              <em>None</em>
            </MenuItem>

            {
              data &&
              data.map((d, i) => (
                <MenuItem value={d} key={i}>
                  {d}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
      </>
    );
  }
}
function SelectBox({  name, field, data, handleSelect, value, ...rest  }) {
  return (
    <>
      <TextField
        InputLabelProps={{
          shrink: true,
        }}
        variant="outlined"
        type="text"
        label={field}
        name={name}
        // value={volumeObs}
        value={value}
        inputProps={{ list: field }}
       
        {...rest}
      />
      <datalist id={field}>
        {
          data && data.map((option, i) => (
            <option key={i} value={option}> {option}</option>
          ))
        }
      </datalist>

    </>
  )
}
export default MuiForm;
