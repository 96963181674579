import React, { Component } from "react";
import Error from "../Error/";
import { Create, Read } from "../../controllers/";
import Close from "@material-ui/icons/Close";
import Delete from "@material-ui/icons/Delete";
import {
  Modal,
  Box,
  IconButton,
  Button,
  LinearProgress,
  Collapse,
} from "@material-ui/core";
import { DeleteOne } from "../../controllers/Remove/DeleteOne";
import { Alert } from "@material-ui/lab";
class BDC_OMC extends Component {
  constructor() {
    super();
    this.state = {
      action: "",
      bdc: "",
      omc: "",
      brv: "",
      err: [],
      data: [],
      response: { type: "", message: "" },
      requesting: false,
      open: false,
    };
  }
  componentDidMount = async () => {
    const { action } = this.props;
    let data = [];
    data = await Read(`/${action?.toLowerCase()}`);
    if (data) {
      let c = data.map((d) => d.name);
      //  //console.log(c);
      this.setState({ data: c });
    } else {
      this.setState({ err: ["Something went wrong"] });
    }
  };
  handleInput = (m, n) => {
    this.setState({ [m]: n });
  };
  UPDATE_BDC = async (e) => {
    const { data, bdc } = this.state;
    e.preventDefault();
    if (bdc === "") {
      this.setState({ err: ["BDC name is required"] });
    } else {
      //send request
      const add = await Create("/bdc/add", { name: bdc.toUpperCase() });
      if (add) {
        data.unshift(bdc.toUpperCase());
        this.setState({
          bdc: "",
          data: [...data, bdc.toUpperCase()],
          response: { type: "success", message: "BDC added successfully" },
        });
      } else {
        this.setState({
          response: {
            type: "error",
            message: "Could not add BDC, try again !",
          },
        });
      }
    }
  };
  UPDATE_OMC = async (e) => {
    const { data, omc } = this.state;
    e.preventDefault();
    if (omc === "") {
      this.setState({ err: ["OMC name is required"] });
    } else {
      //send request
      data.unshift(omc.toUpperCase());
      const add = await Create("/omc/add", { name: omc.toUpperCase() });
      if (add) {
        this.setState({
          omc: "",
          data: [...data, omc.toUpperCase()],
          response: { type: "success", message: "OMC added successfully" },
        });
      } else {
        this.setState({
          response: {
            type: "error",
            message: "Could not add OMC, try again !",
          },
        });
      }
    }
  };
  UPDATE_BRV = async (e) => {
    e.preventDefault();
    const { data, brv } = this.state;
    if (brv === "") {
      this.setState({ err: ["BRV name is required"] });
    } else {
      //send request
      const add = await Create("/brv/add", { name: brv.toUpperCase() });
      if (add) {
        const n = data.unshift(brv.toUpperCase());
        this.setState({
          brv: "",
          data: [...data, brv.toUpperCase()],
          response: { type: "success", message: "BRV added successfully" },
        });
      } else {
        this.setState({
          requesting: false,
          response: {
            type: "error",
            message: "Could not add BRV, try again !",
          },
        });
      }
    }
  };
  onDelete = async (n) => {
    ////console.log(n,this.state.action);

    this.setState({ requesting: true });
    const data = this.state.data;
    const item = data[n];

    const del = await DeleteOne(`/${this.props.action}/${encodeURI(item)}`);
    if (del) {
      data.splice(n, 1);
      this.setState({
        requesting: false,
        data,
        response: { type: "success", message: item + " has been deleted !" },
      });
    } else {
      this.setState({
        requesting: false,
        response: { type: "error", message: "Could not delete " + item + " !" },
      });
    }
  };
  closeAlert = () => {
    this.setState({ response: { type: "", message: "" } });
  };
  //modal
  handleOpen = () => {
   
	this.setState({open:true})
  };

  handleClose = () => {
   
	this.setState({ open: false });
  };

  render() {
    const {open, err, data, response, requesting } = this.state;

    const { action, unmount } = this.props;

    return (
      <>
        <Modal open={open} onClose={this.handleClose}></Modal>

        <div className="hjOpfT2">
          {err.length > 0 ? <Error msg={err} /> : null}

          <div className="prompt">
            <form className="confirm">
              {response.message !== "" ? (
                <Collapse in={response.message !== "null"}>
                  <Alert
                    action={
                      <Button
                        color="inherit"
                        size="small"
                        onClick={this.closeAlert}
                        style={{
                          fontSize: ".7rem",
                          width: 20,
                          height: 20,
                          textTransform: "none",
                        }}
                      >
                        Hide
                      </Button>
                    }
                    severity={response.type}
                    style={{
                      width: 250,
                      position: "absolute",
                      fontSize: ".8rem",
                    }}
                  >
                    {response.message}
                  </Alert>
                </Collapse>
              ) : null}
              {requesting ? <LinearProgress /> : null}

              <Box
                sx={{
                  marginTop: "-20px",
                  position: "absolute",
                  width: "250px",
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <IconButton
                  style={{
                    marginRight: "-20px",
                    position: "relative",
                    backgroundColor: "#fff",
                    width: 35,
                    height: 35,
                  }}
                  onClick={() => {
                    return unmount();
                  }}
                >
                  <Close size="large" />
                </IconButton>
              </Box>
              <div className="fm-main-field">
                {action === "BDC" ? (
                  <div className="fm-part-group ">
                    <Input
                      type="text"
                      handler={this.handleInput}
                      lbl="BDC"
                      name="bdc"
                      value={this.state.bdc}
                    />
                    <button
                      type="submit"
                      className="submitBtn"
                      onClick={this.UPDATE_BDC}
                    >
                      ADD
                    </button>
                  </div>
                ) : null}
                {action === "OMC" ? (
                  <div className="fm-part-group ">
                    <Input
                      type="text"
                      handler={this.handleInput}
                      lbl="OMC"
                      name="omc"
                      value={this.state.omc}
                    />
                    <button
                      type="submit"
                      className="submitBtn"
                      onClick={this.UPDATE_OMC}
                    >
                      ADD
                    </button>
                  </div>
                ) : null}
                {action === "BRV" ? (
                  <div className="fm-part-group ">
                    <Input
                      type="text"
                      handler={this.handleInput}
                      lbl="BRV"
                      name="brv"
                      value={this.state.brv}
                    />
                    <button
                      type="submit"
                      className="submitBtn"
                      onClick={this.UPDATE_BRV}
                    >
                      ADD
                    </button>
                  </div>
                ) : null}
              </div>

              <ListData data={data} onDelete={this.onDelete} type={action} />
            </form>
          </div>
        </div>
      </>
    );
  }
}

//export default withRouter(BDC_OMC);
export default BDC_OMC;

function ListData({ data, onDelete }) {
  const deleteItem = (index) => {
    return onDelete(index);
  };
  return (
    <>
      <div className="erTf">
        <table className="bost-tbl">
          <tbody>
            {data.map((d, i) => (
              <tr
                key={i}
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                <td
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div>{d}</div>

                  <IconButton
                    onClick={deleteItem.bind(this, i)}
                    style={{
                      border: 0,
                      height: 30,
                      width: 30,
                      padding: 5,
                      marginRight: 10,
                    }}
                  >
                    <Delete style={{ color: "red" }} />
                  </IconButton>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
}
function Input({ name, lbl, type, handler, ...rest }) {
  function inputHandler(e) {
    return handler(e.target.name, e.target.value);
  }
  return (
    <div className="fieldEl">
      <div>
        <label>{lbl}</label>
      </div>
      <input type={type} name={name} onChange={inputHandler} {...rest} />
    </div>
  );
}
