import { Alphabets, Numeric, AlphaNumeric } from '../../Helpers/validation';
export default function formValidate(data) {
	let errs = [];
	
	// others
	if(data.uid === 0){
		return errs['An unexpexted error occurred']
	}
	const others = {
		brv_num: data.brv_num,
		fan_num: data.fan_num,
		//coll_order: data.coll_order,
		compartmentReleased: data.compartmentReleased,
		releasing_tank: data.releasing_tank
	};

	if (!others.brv_num) {
		errs.push('BRV number is required');
	}

	if (!others.fan_num) {
		errs.push('FAN number is required');
	}
	if (!others.releasing_tank) {
		errs.push('Releasing Tank field is required');
	}

	// if (!others.coll_order) {
	// 	errs.push('Collection order is required');
	// }

	if (!others.compartmentReleased) {
		errs.push('Compartments cannot be empty');
	}

	//alphabets
	const alpha = {
		product_type: data.product_type,
		bdc: data.bdc,
		omc: data.omc,
		destination: data.destination,
		driver: data.driver,
		issuer: data.issuer,
	};

	if (!alpha.product_type) {
		errs.push('Invalid entry for Product type');
	}

	if (!alpha.bdc) {
		errs.push('Invalid entry for BDC');
	}

	if (!alpha.omc) {
		errs.push('Invalid entry for OMC');
	}

	if (!Alphabets(alpha.destination)) {
		errs.push('Invalid entry for Destination');
	}

	if (!Alphabets(alpha.driver)) {
		errs.push('Invalid entry for Driver');
	}

	if (!Alphabets(alpha.issuer)) {
		errs.push('Invalid entry for Issuer');
	}

	//validate nums
	const nums = {
		released_1: data.released_1,
		released_2: data.released_2,
		released_3: data.released_3,
		released_4: data.released_4,
		released_5: data.released_5,
		released_6: data.released_6,
		released_7: data.released_7,
		vcf: data.vcf,
		litre_temp: data.litre_temp,
		litre_density_at_20: data.litre_density_at_20,
		total: data.total,
		volumeAt20: data.volumeAt20,
	};

	if (!Numeric(nums.released_1)) {
		errs.push('Invalid number format at Compartment 1');
	}

	if (!Numeric(nums.released_2)) {
		errs.push('Invalid number format at Compartment 2');
	}

	if (!Numeric(nums.released_3)) {
		errs.push('Invalid number format at Compartment 3');
	}

	if (!Numeric(nums.released_4)) {
		errs.push('Invalid number format at Compartment 4');
	}

	if (!Numeric(nums.released_5)) {
		errs.push('Invalid number format at Compartment 5');
	}
	if (!Numeric(nums.released_6)) {
		errs.push('Invalid number format at Compartment 6');
	}
	if (!Numeric(nums.released_7)) {
		errs.push('Invalid number format at Compartment 7');
	}
	if (isNaN(nums.vcf)) {
		errs.push('Invalid number format at VCF');
	}
	if (isNaN(nums.litre_temp)) {
		errs.push('Invalid number format at L/Temperature');
	}
	if (isNaN(nums.litre_density_at_20)) {
		errs.push('Invalid number format at L/Density');
	}
	if (isNaN(nums.total)) {
		errs.push('Invalid number format at Total');
	}
	if (isNaN(nums.volumeAt20)) {
		errs.push('Invalid number format at Volume@20');
	}

	//alphanumeric
	if (!data.rack_num){
		errs.push('Rack # is required')
	}
	if (!data.loader) {
		errs.push('Loader name is required')
	}

	
	return errs;
}

