import React, { Component } from 'react';
import { ToggleSwitch } from '../../Components/Elements/';
import { Link } from "react-router-dom"
import { Read } from '../../controllers/Read';
import Error from "../../Components/Error/"
import {Typography} from '@material-ui/core'
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';


//----------------------------------------------------------------------------------------
export default class Users extends Component {
	constructor() {
		super();
		this.state = {
			users: []
		};
	}
	componentDidMount = async () => {
		const get = await Read('/user')
		if (get) {
			this.setState({ users: get })
			////console.log(get)
			this.handleLoader(false)
		} else {
			this.handleLoader(false)
			this.setState({ error: true })
		}

	}
	handleLoader = (n) => {
		const { process } = this.props;
		return process(n)
	}
	
	render() {
		const { users, error } = this.state;
		return (
			<div>
				{
					error ? <Error msg={["Something went wrong, Please Try again"]} />
						: null
				}
				<Link to="/admin/users/create">Add User</Link>
				
				<TableContainer>
					<Table>
						<TableHead>
							<TableRow>
								<TableCell>Name</TableCell>
								<TableCell>Email</TableCell>
								<TableCell>Contact</TableCell>
								<TableCell>Level</TableCell>
								<TableCell>Verified</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{
								users.map((u,i)=>(
									<TableRow key={i}>
										<TableCell>{u.fullName}</TableCell>
										<TableCell>{u.email}</TableCell>
										<TableCell>{u.contact}</TableCell>
										<TableCell>
											<RoleLabel role={u.level}/>
										</TableCell>
										<TableCell>

											<ToggleSwitch email={u.email} bool={u.verified} />
										</TableCell>
									</TableRow>
								))
							}
							
						</TableBody>
					</Table>
				</TableContainer>
			

			</div>
		);
	}

}
const RoleLabel = ({ role }) => {
	
	const style={
		height:'auto',
		width:60,
		textAlign:'center',
		color:'#fff',
		fontSize:'.8rem',
		padding:5,
		borderRadius:999,
		backgroundColor: role?.toLowerCase() === 'admin' ? '#085eba' :'#0bad39'
	}
	return <>
		<div style={style}>
		<Typography >
			{role?.toLowerCase()}
		</Typography>
		</div>
	</>

}