import React from 'react'
import { useLocation, Outlet } from 'react-router-dom'
import { useAuth } from '../../lib/use-auth'
//--------------------------------------------

export default function EntryRoleGuard({children}) {
  const location = useLocation();
  const { user } = useAuth();
 // console.log(location,'auth')
  return (
    <>
      {
        user && user.level === 'user' && children
      }
      <Outlet/>
    </>
  )
}
