import React from 'react'
import { useLocation, Outlet, Navigate } from 'react-router-dom'
import { useAuth } from '../../lib/use-auth'
import ErrorPage from '../ErrorPage';
//--------------------------------------------

export default function FallbackGuard() {
  const location = useLocation();
  const { user } = useAuth();
  //console.log(user,'auth')
  return (
    <>
      {
        user && user.level === 'user' && <Navigate to='/app' replace/>
      }
      {
        user && user.level === 'admin' && <Navigate to='/admin' replace/>
      }
      {/* if user isnt available yet wait for a few secons before flashing the 500 page */}
      {
       //!user && <ErrorPage/>
      }
      <Outlet/>
    </>
  )
}
