//name of collection or table
import httpAxios from '../../config/axios'
import {logOut} from "../../Auth"
const Create = async (ENDPOINT, data) => {
 
  try {
    const create = await httpAxios.post(`${ENDPOINT}`,data);
    if (create) {
     
      return create.data;
    }
    return false;
  } catch (error) {
   
    if(error.response?.status===401){
      logOut()
    }
    return false;
  }
};
export default Create