import React, { Component } from 'react';
import { Read, Create } from '../../controllers/';
import { Input, Select } from '../../Components/Elements/form-input/input';
import { bdc, omc } from '../../Components/Elements/Constants';
import Error from '../../Components/Error/';
import { fDate } from '../../Helpers/Dates';
import { CSVLink } from 'react-csv';
import { DeleteOne } from '../../controllers/Remove/DeleteOne';
import { userDetail } from '../../Auth/';
import ConfirmWindow from '../../Components/prompto';
import MuiFilterForm from './MuiFilterForm';
import Delete from '@material-ui/icons/Delete'
import { Modal, Button, IconButton } from '@material-ui/core';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
//--------------------------------------------------=--------------------------------------
const FilterIcon = () => (
	<>
		<svg className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium MuiBox-root css-uqopch" fill='#222B47' style={{ color: '#fff' }} focusable="false" viewBox="0 0 24 24" aria-hidden="true" data-testid="FilterAltIcon"><path d="M4.25 5.61C6.27 8.2 10 13 10 13v6c0 .55.45 1 1 1h2c.55 0 1-.45 1-1v-6s3.72-4.8 5.74-7.39c.51-.66.04-1.61-.79-1.61H5.04c-.83 0-1.3.95-.79 1.61z"></path></svg>
	</>
)
export default class Report extends Component {
	constructor() {
		super();
		this.state = {
			records: [],
			dateFrom: null,
			dateTo: null,
			omc: '',
			brv: '',
			brv_num: '',
			product_type: '',
			bdc: '',
			brvs: [],
			GRAND_TOTAL: 0,
			VOLAT20_TOTAL: 0,
			fileName: '',
			csvData: [],
			stamp: 0,
			fanBillNum: '',
			checked: '',
			currUser: '',
			deleteDialog: false,
			paginate: null,
			open: false
		};
	}
	//PAGINATION
	loadMoreRows = () => {
		//const { records } = this.state;
		////console.log(Math.floor(records.length / 100), 120 / 100);


		for (let i = 0; i < 100; i++) {
			////console.table(records[i])
		}
	};

	componentDidMount = async () => {
		const usr = userDetail();
		this.setState({ currUser: usr.uid });
		const epoch = Math.round(Date.now() / 1000);
		this.setState({ stamp: epoch });
		const getBRVs = await Read('/brv')
		if (getBRVs) {

			let arr = getBRVs.length > 0 ? getBRVs.map(b => b.name) : []

			this.setState({ brvs: arr })
		}




		const get = await Read('/fan-bill');
		//	////console.log(get);
		if (get) {
			let v2 = get.map(t => t.volumeAt20);
			let tt = get.map(t => t.total);
			let tmp1 = 0;
			let tmp2 = 0;
			for (let i = 0; i < v2.length; i++) {
				tmp1 += parseFloat(v2[i]);
			}
			for (let i = 0; i < tt.length; i++) {
				tmp2 += parseFloat(tt[i]);
			}
			this.setState({ records: get, GRAND_TOTAL: tmp2, VOLAT20_TOTAL: tmp1 });

			this.preloaderProcess(false);
			this.loadMoreRows();
		} else {
			this.setState({ err: true });
		}
		//{"DATE","PRODUCT","TRUCK","BDC","OMC","VOLUME@20 (Lts)","VOLUME OBS. (Lts)"}
		//prepare data for excel export
		this.newGetCsvDataFormat();
	};
	calcTotals = () => {
		const { records } = this.state;
		let v2 = records.map(t => t.volumeAt20);
		let tt = records.map(t => t.total);
		let tmp1 = 0;
		let tmp2 = 0;
		for (let i = 0; i < v2.length; i++) {
			tmp1 += parseFloat(v2[i]);
		}
		for (let i = 0; i < tt.length; i++) {
			tmp2 += parseFloat(tt[i]);
		}
		this.setState({ GRAND_TOTAL: tmp2, VOLAT20_TOTAL: tmp1 });
	};
	preloaderProcess = n => {
		let { process } = this.props;
		return process(n);
	};
	handler = (m, n) => {
		this.setState({ [m]: n });
	};
	handleDate = d => {
		if (d) {
			const date = new Date(d);
			const dx = date.getFullYear() + '-' + date.getMonth() + '-' + date.getDate();
			return dx;
		}
		return null;
	};
	clearSearch = () => {
		//this.setState({product_type:'',omc:'',bdc:'',})
	}
	onSubmit = async e => {
		e.preventDefault();
		this.preloaderProcess(true);
		const epoch = Math.round(Date.now() / 1000);
		this.setState({ stamp: epoch, open: false });
		const { dateFrom, dateTo, fanBillNum, omc, bdc, brv_num, product_type } = this.state;
		//console.log(dateFrom, dateTo, omc, bdc, product_type);
		if (dateFrom || dateTo || omc || bdc || brv_num || product_type) {
			const post = await Create('/fan-bill/filter', {
				dateFrom,
				dateTo,
				omc,
				bdc, brv_num,
				product_type,
				fan_num: fanBillNum,
			});

			if (post) {
				let v2 = post.map(t => t.volumeAt20);
				let tt = post.map(t => t.total);
				let tmp1 = 0;
				let tmp2 = 0;
				for (let i = 0; i < v2.length; i++) {
					tmp1 += parseFloat(v2[i]);
				}
				for (let i = 0; i < tt.length; i++) {
					tmp2 += parseFloat(tt[i]);
				}
				this.setState({ records: post, GRAND_TOTAL: tmp2, VOLAT20_TOTAL: tmp1 });
				this.preloaderProcess(false);
			} else {
				this.setState({ err: true });
			}


		}
	};


	newGetCsvDataFormat = () => {
		const csvData = [
			['DATE', 'TRANSACTION #', 'TRUCK #', 'BDC', 'OMC', 'PRODUCT', 'VOLUME@20 (Lts)', 'VOLUME OBS', 'L/TEMPERATURE (°C)', 'L/DENSITY@20 (g/ml)', 'DESTINATION', 'RELEASING TANK', 'RACK #', 'LOADER'],
		];
		const get = this.state.records;
		const { GRAND_TOTAL, VOLAT20_TOTAL } = this.state;
		for (let i = 0; i < get.length; i += 1) {
			csvData.push([
				`${fDate(get[i].record_date)}`,
				`${get[i].fan_num}`,
				`${get[i].brv_num}`,
				`${get[i].bdc}`,
				`${get[i].omc}`,
				`${get[i].product_type}`,
				`${get[i].volumeAt20}`,
				`${get[i].total}`,
				`${get[i].litre_temp}`,
				`${get[i].litre_density_at_20}`,
				`${get[i].destination}`,
				`${get[i].releasing_tank}`,
				`${get[i].rack_num}`,
				`${get[i].loader}`,
			]);
		}
		csvData.push([``, ``, ``, ``, ``, ``, `${VOLAT20_TOTAL}`, GRAND_TOTAL, ``, ``, ``, ``, ``]);

		return csvData;
	};

	handleDialog = async e => {
		////console.log(e);
		if (e) {
			await this.del();
			await this.calcTotals();
			this.setState({ deleteDialog: false });
		} else {
			this.setState({ deleteDialog: false });
		}
	};
	showDialog = () => {
		this.setState({ deleteDialog: true });
	};
	handleOpen = () => {
		this.setState({ open: true })
	}
	handleClose = () => {
		this.setState({ open: false })

	}
	//select * from fanbill where DATE(record_date) = DATE(NOW())
	render() {
		const { open, deleteDialog, checked, stamp, err, records, GRAND_TOTAL, VOLAT20_TOTAL } = this.state;
		//console.log(records)
		return (
			<div style={{ display: 'block' }}>
				{deleteDialog ? (
					<ConfirmWindow msg="Confirm to delete selected row" action={this.handleDialog} />
				) : null}

				{err ? <Error msg={['Something went wrong, Please Try again']} /> : null}

				{/* {checked !== '' ? ( 
					<button className="delete-row-btn" onClick={this.showDialog}> 
						&times; 
				</button> 
				) : null}  */}
				<Button variant='contained' style={{ marginRight: 20, backgroundImage: 'linear-gradient(to right,#222B47,#222b47a1)' }} onClick={this.handleOpen}>

					<FilterIcon />
				</Button>
				<Modal open={open}
					onClose={this.handleClose}
					aria-labelledby="modal-modal-title"
					aria-describedby="modal-modal-description">
					<form onSubmit={this.onSubmit}>
						<MuiFilterForm handleChange={this.handler} handleClose={this.handleClose} products={['AGO', 'PMS', 'PMS-95']} bdcs={bdc} omcs={omc} brvs={this.state.brvs} />
					</form>
				</Modal>
				<span style={{ color: '#888' }}>Search results {`( ${records.length} )`}</span>
				{records ? <ExportReactCSV csvData={this.newGetCsvDataFormat()} fileName={`BOST-KSI-${stamp}.csv`} /> : null}
				<>
					<TableContainer>
						<Table size='small' aria-label="a reports table">
							<TableHead>
								<TableRow>
									<TableCell>DATE</TableCell>
									<TableCell>TRANSACTION #</TableCell><TableCell>PRODUCT</TableCell><TableCell>BRV #</TableCell><TableCell>BDC</TableCell><TableCell>OMC</TableCell><TableCell>VOLUME@20 (Lts)</TableCell>
									<TableCell>VOLUME OBS. (Lts)</TableCell>
									<TableCell>L/TEMPERATURE (°C)</TableCell>
									<TableCell>L/DENSITY@20 (g/ml)</TableCell>
									<TableCell>DESTINATION</TableCell>
									<TableCell>RELEASING TANK</TableCell>
									<TableCell>RACK #</TableCell>
									<TableCell>LOADER</TableCell>
									<TableCell>ACTION</TableCell>
								</TableRow>

							</TableHead>
							<TableBody>
								{
									records && records.map((r) => (
										<TableRow key={r.id}>

											<TableCell>{fDate(r.record_date)}</TableCell>
											<TableCell>{r.fan_num}</TableCell>
											<TableCell>{r.product_type}</TableCell>
											<TableCell>{r.brv_num}</TableCell>
											<TableCell>{r.bdc}</TableCell>
											<TableCell>{r.omc}</TableCell>
											<TableCell>{r.volumeAt20}</TableCell>
											<TableCell>{r.total}</TableCell>
											<TableCell>{r.litre_temp}</TableCell>
											<TableCell>{r.litre_density_at_20}</TableCell>
											<TableCell>{r.destination?.toUpperCase()}</TableCell>
											<TableCell>{r.releasing_tank}</TableCell>
											<TableCell>{r.rack_num}</TableCell>
											<TableCell>{r.loader?.toUpperCase() || ''}</TableCell>

											<TableCell>

												<IconButton onClick={this.handleCheck.bind(this, r.id)} style={{}}>

													<Delete style={{ color: 'red' }} />
												</IconButton>
											</TableCell>
										</TableRow>
									))
								}

							</TableBody>

							<TableBody style={{position:'fixed',bottom:0}}>
								<TableRow>
									<TableCell>VOLUME@20 {VOLAT20_TOTAL}</TableCell>
									<TableCell>VOLUME OBS. {GRAND_TOTAL}</TableCell>
								</TableRow>
							</TableBody>

						</Table>
					</TableContainer>


				</>
				<div className="tbl-wrap2" style={{ width: 'calc(100% - 200px)' }}>
					{/*<table className="bost-tbl" style={{ overflow: 'scroll' }}>
						<tbody>
							{records.length > 0 ? (
								<tr className="">

									<th className="sticky">DATE</th>
									<th className="sticky">TRANSACTION #</th>
									<th className="sticky">PRODUCT</th>
									<th className="sticky">BRV #</th>
									<th className="sticky">BDC</th>
									<th className="sticky">OMC</th>
									<th className="sticky">VOLUME@20 (Lts)</th>
									<th className="sticky">VOLUME OBS. (Lts)</th>
									<th className="sticky">L/TEMPERATURE (°C)</th>
									<th className="sticky">L/DENSITY@20 (g/ml)</th>
									<th className="sticky">DESTINATION</th>
									<th className="sticky">RELEASING TANK</th>
									<th className="sticky">RACK #</th>
									<th className="sticky">LOADER</th>
									<th className="sticky" style={{ zIndex: 10 }}>ACTION</th>

								</tr>
							) : null}
							{(records || []).map((r, i) => (
								<tr key={i}>
									
									<td>{fDate(r.record_date)}</td>
									<td>{r.fan_num}</td>
									<td>{r.product_type}</td>
									<td>{r.brv_num}</td>
									<td>{r.bdc}</td>
									<td>{r.omc}</td>
									<td>{r.volumeAt20}</td>
									<td>{r.total}</td>
									<td>{r.litre_temp}</td>
									<td>{r.litre_density_at_20}</td>
									<td>{r.destination}</td>
									<td>{r.releasing_tank}</td>
									<td>{r.rack_num}</td>
									<td>{r.loader}</td>

									<td>

										<IconButton onClick={this.handleCheck.bind(this, r.id)} style={{}}>

											<Delete style={{ color: 'red' }} />
										</IconButton>
									</td>
								</tr>
							))}
							{records.length > 0 ? (
								<tr>
									<td className="sticky-btm-row" colSpan="6"></td>
									<td className="sticky-btm-row">
										<strong>{VOLAT20_TOTAL}</strong>
									</td>
									<td className="sticky-btm-row" style={{}}>
										<strong>{GRAND_TOTAL}</strong>
									</td>
								</tr>
							) : null}
						</tbody>
					</table>*/}
				</div>
			</div>
		);
	}

	del = async () => {
		const { checked, currUser, records } = this.state;
		if (checked !== '' && currUser !== '') {
			const rem = await DeleteOne(`/fan-bill/remove/${currUser}/${checked}`);
			if (rem) {
				const dl = records.filter(r => {
					if (r.id !== Number(checked)) {
						return r;
					}
				});
				//const index = records.findIndex()
				////console.log(dl);
				this.setState({ records: [...dl] });
			}
		} else {
			this.setState({ err: true });
		}
	};
	handleCheck = (id) => {
		//console.log(id)
		let { checked } = this.state;
		const val = checked === id ? '' : id;
		//console.log(val)
		this.setState({ checked: val, deleteDialog: true });
	};
}



export const ExportReactCSV = ({ csvData, fileName }) => {
	return (
		<div className="yuOp2">
			<button className="submitBtn to-excel-btn">
				<CSVLink data={csvData} filename={fileName}>
					<img src={`${process.env.PUBLIC_URL}/excel.png`} alt="export to excel" />
				</CSVLink>
			</button>
		</div>
	);
};
