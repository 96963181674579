const bdc = [
	'ALPHA PET',
	'ASTRA',
	'BLUE OCEAN',
	'BATTOP ENERGY',
	'CHASE',
	'CIRRUS',
	'DOMINION',
	'EAGLE PETROLEUM',
	'EBONY',
	'ECO-PET / SAGE',
	'EVERSTONE',
	'FIRM ENERGY',
	'FUEL TRADE',
	'GENYSIS',
	'G.O ENERGY',
	'JEWEL ENERGY',
	'L.H.S',
	'MARANATHA',
	'P.W.S.L',
	'RAMA',
	'VIHAMA',
];
const omc = [
	'AI ENERGY',
	'ALLIED OIL COMPANY',
	'AP OIL & GAS',
	'BEAP ENERGY',
	'CASH OIL',
	'COMPASS OLEUM',
	'CROWN PETROLEUM',
	'DEEP PETROLEUM',
	'DESERT OIL',
	'DUKES PETROLEUM',
	'ENGEN GHANA',
	'EV OIL',
	'EXCELL OIL',
	'FRAGA OIL',
	'FRIMPS',
	'FRONTIER OIL',
	'GALAXY OIL',
	'GASO PETROLEUM',
	'GHANA OIL COMPANY (GOIL)',
	'GLASARK',
	'GLORY',
	'GOODNESS ENERGY',
	'GRID PETROLEUM',
	'GULF ENERGY',
	'IBM PETROLEUM',
	'INFIN GHANA',
	'JD-LINK OIL',
	'JO & JU OIL COMPANY',
	'JP TRUSTEES',
	'JUSBRO',
	'KAN ROYAL',
	'KINGS ENERGY',
	'MAXX ENERGY',
	'MERCY OIL MARKETING',
	'NASONA OIL',
	'ORIENT ENERGY',
	'PACIFIC OIL GHANA',
	'PETROLAND LIMITED',
	'PETROSOL GHANA',
	'PLUS ENERGY',
	'PUMA ENERGY',
	'Q8',
	'QUANTUM PETROLEUM',
	'RADIANCE OIL',
	'SEAM OIL',
	'STAR OIL',
	'TOTAL',
	'VENUS OIL',
	'VIVO ENERGY',
	'INEST AFRICAN PETROLEUM COMPANY',
	'ZEN PETROLEUM',
];
export {omc,bdc}