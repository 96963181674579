import React, { useState ,useEffect} from "react";
//import MenuList from "./menu-list";
import { Link } from "react-router-dom";
import { userDetail } from "../../Auth";
import MenuIcon from "@material-ui/icons/Menu";
import ExitToApp from "@material-ui/icons/ExitToApp";
import Description from "@material-ui/icons/Description";
import { useNavigate } from "react-router-dom";
import { useAuth } from '../../lib/use-auth'
import depotMapper from '../../depotMapper'
import {Read} from '../../controllers/'
//------------------------------------------
import {
    AppBar,
    Box,
    Divider,
    Drawer,
    FormControl,
    IconButton,
    MenuItem,
    Toolbar,

} from "@material-ui/core";
//---------------------------------------------------

function Header(props) {
    const {user,signout} =useAuth()
  
    const [menuToggle, setmenuToggle] = useState(false)
    const [role, useRole] = useState('')
    const [depot,setDepot]=useState('')
  //  const [user, setUser] = useState(null)
    const toggleMenu = () => {
        let toggle = menuToggle === false ? true : false;
        setmenuToggle(toggle)
    };

useEffect(()=>{
    async function getDepot(){
         const d = await Read('/user/depot');
         if(d){
            setDepot(d)
         }
    }
    getDepot()

},[])
    return (
        <>
            <div className="main-header-wrapper">
                
                {user  ? (
                    <AppBar
                        position="fixed"
                        variant="outlined"
                        style={{
                            backgroundColor: "#fff",
                            borderBottom: "1px solid #ddd",
                            height: 55,
                        }}
                    >
                        <Toolbar
                            style={{
                                display: "flex",
                                justifyContent: "space-between",
                                color: "#222B47",
                                fontSize: ".8rem",
                            }}
                        >
                            <Box>
                                <AppDrawer user={user} depot={depot} signout={signout} />
                            </Box>
                            <Link to="/" className="a">
                                <div className="elLogo">
                                    <img
                                        src={`${process.env.PUBLIC_URL}/bostLogo.png`}
                                        height="45px"
                                        alt="BOST"
                                        style={{
                                            padding: 2,
                                            border: "2px solid #ddd",
                                            borderRadius: 9999,
                                            height: 50,
                                            width: 50,
                                        }}
                                    />

                                </div>

                            </Link>
                            <Box sx={{ display: "flex", alignItems: "center" }}>
                                <IconButton onClick={signout}>
                                    <ExitToApp />
                                </IconButton>
                                <FormControl
                                    style={{ cursor: "pointer" }}
                                    onClick={()=>signout()}
                                >
                                    Logout
                                </FormControl>
                            </Box>

                        </Toolbar>

                    </AppBar>
                ) :
                    null}

                {/* <AppDrawer /> */}

            </div></>
    );

}




const AppDrawer = ({ user, signout ,depot}) => {
    const [state, setState] = React.useState({
        left: false,
    });
    const navigate = useNavigate();
    const toggleDrawer = (anchor, open) => (event) => {
        if (
            event.type === "keydown" &&
            (event.key === "Tab" || event.key === "Shift")
        ) {
            return;
        }

        setState({ ...state, [anchor]: open });
    };
    return (
        <Box>
            <IconButton onClick={toggleDrawer("left", true)}>
                <MenuIcon />
            </IconButton>
            <Drawer
                anchor={"left"}
                open={state["left"]}
                onClose={toggleDrawer("left", false)}
            >
                <Box style={{ padding: "2rem", width: 350 }}>
                    <h2 style={{ fontWeight: 500 }}>Welcome, {user ? user.fullName : ""}</h2>
                    <h5>{depot || 'depot'}</h5>
                    <br />
                    <Divider />
                </Box>
                {user && user.level !== "admin" ? (
                    <MenuItem
                        onClick={() => {
                            navigate("/app/waybill");
                        }}
                    >
                        <Description style={{ color: "#333" }} />
                        &nbsp;&nbsp;&nbsp;Waybill
                    </MenuItem>
                ) : null}
                <MenuItem onClick={signout}>
                    <ExitToApp /> &nbsp;&nbsp;&nbsp;Logout
                </MenuItem>
            </Drawer>
        </Box>
    );
};
export default Header;
