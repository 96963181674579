import React, { Component, useEffect, useState } from "react";
import { Create, Read } from "../../controllers/";
//import Read from "../../controllers"
import Header from "../header/";
//import Error from "../Error/";
import Preloader from "../preloader/";
import formValidate from "./form.validate";
import { fDate } from "../../Helpers/Dates";
import httpAxios from "../../config/axios";
import { Alert } from "@material-ui/lab";
import {FormControl,InputLabel,Select,MenuItem} from '@material-ui/core'
import Send from "@material-ui/icons/Send";
import Add from "@material-ui/icons/AddCircle";
import { useNavigate } from "react-router-dom";
import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined';

import BDC_OMC from "../BDC-OMC/";
import MUIaddForm from "../BDC-OMC/MUIaddForm"
import {
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Collapse,
  FormLabel,
  Grid,
  IconButton,
  TextField,
  Tooltip,Modal,Paper
} from "@material-ui/core";
import MuiForm from "./MuiForm";
import { useStyles } from "./muistyles";
import { Link } from "react-router-dom";
import {authContext} from '../../lib/use-auth';

//------------------------------------------------------------
export default class Form extends Component {
  static contextType = authContext;
  constructor(props) {
   
    super(props);
    this.state = {
      updateBDC_OMC: "",
      preloader: true,
      date: null,
      brv_num: "",
      product_type: "",
      vcf: 0,
      litre_temp: 0,
      litre_density_at_20: 0,
      fan_num: "",
      coll_order: "",
      bdc: "",
      omc: "",
      destination: "",
      driver: "",
      issuer: "",
      compartments: ["1"],
      compQty: 1,
      total: 0,
      released_1: 0,
      released_2: 0,
      released_3: 0,
      released_4: 0,
      released_5: 0,
      released_6: 0,
      released_7: 0,
      volumeAt20: 0,
      uid: 0,
      email: "",
      omcs: [],
      bdcs: [],
      requestStatus: null,
      errs: [],
      brvs: [],
      isSubmitting: false,
      showAlert: true,
      suggestions:[],
      available_brv_for_update:[],
      releasing_tank:'',
      rack_num: '',
      loader:'',
      open:false,
      openSettings:false
    };
  }
  EntryFormCard = {
    width: 370,
    margin: "auto",
    padding: "2.5rem",
    marginTop: "5rem",
    borderRadius: 0,
  };
  componentDidMount = async () => {
  const brv_recall_list = await Read(`/fan-bill/recall-brv/list`);
if(brv_recall_list){
 // console.log(brv_recall_list)
  this.setState({available_brv_for_update:brv_recall_list})
}else{
 // console.log(brv_recall_list," failed brv list update")
}

    const user = this.context?.user;
    if (user) {
      this.setState({ uid: user.uid });
    }
    const d = fDate(new Date());
    this.setState({ date: d, preloader: false });

    //fetch omc - bdcs
    const bdcs = await Read("/bdc");
    if (bdcs) {
      let v = bdcs.map((b) => b.name);
      this.setState({ bdcs: v });
    }
    const omcs = await Read("/omc");
    if (omcs) {
      let v = omcs.map((b) => b.name);
      this.setState({ omcs: v });
    }
    const brvs = await Read("/brv");
    if (brvs) {
      let v = brvs.map((b) => b.name);
      this.setState({ brvs: v });
    }
    //SET & fetch SUGGESTIONS
    let suggestions = localStorage.getItem("suggestions");
    if (!suggestions) {
      let sugg = []

      localStorage.setItem("suggestions", JSON.stringify(sugg));
    }
    let suggObj = JSON.parse(localStorage.getItem("suggestions"));
    this.setState({suggestions: suggObj})
    //current domain
    const curr_domain= await Create('user/footprint')
    if(curr_domain){
      return
    }
  };
  handleTimeout = () => {
    setTimeout(this.setState({ requestStatus: null }), 5000);
  };
  handleLoader = (n) => {
    let tgl = this.state.load === true ? false : true;
    this.setState({ load: tgl });
  };
//--------------------on submit-------------------------------------------------------
  

  //---------------------------------------------------------------------------------------
ONSUBMIT = async (e) => {
    e.preventDefault();
    this.handleLoader(true);
    this.setState({ errs: [], isSubmitting: true, showAlert: true });
    const { uid } = this.state;
    const comp = {
      released_1: parseFloat(this.state.released_1),
      released_2: parseFloat(this.state.released_2),
      released_3: parseFloat(this.state.released_3),
      released_4: parseFloat(this.state.released_4),
      released_5: parseFloat(this.state.released_5),
      released_6: parseFloat(this.state.released_6),
      released_7: parseFloat(this.state.released_7),
    };
    let totalCompartment = 0;
    for (let v of Object.values(comp)) {
      totalCompartment += v;
    }
    let TOTAL_VOL_OBS = parseFloat(
      Math.round(totalCompartment / this.state.vcf)
    );
    const date = fDate(new Date());
    const data = {
      date: date.date,
      brv_num: this.state.brv_num,
      product_type: this.state.product_type,
      vcf: parseFloat(this.state.vcf),
      litre_temp: parseFloat(this.state.litre_temp),
      litre_density_at_20: parseFloat(this.state.litre_density_at_20),
      fan_num: this.state.fan_num,
      coll_order: this.state.coll_order,
      bdc: this.state.bdc,
      omc: this.state.omc,
      destination: this.state.destination,
      driver: this.state.driver,
      issuer: this.state.issuer,
     // total: TOTAL_VOL_OBS,
     total:this.state.total,
      compartments: this.state.compartments,
      volumeAt20: isNaN(totalCompartment) ? 0 : totalCompartment,
      //	compQty: this.state.compQty,
      released_1: parseFloat(this.state.released_1),
      released_2: parseFloat(this.state.released_2),
      released_3: parseFloat(this.state.released_3),
      released_4: parseFloat(this.state.released_4),
      released_5: parseFloat(this.state.released_5),
      released_6: parseFloat(this.state.released_6),
      released_7: parseFloat(this.state.released_7),
      compartmentReleased: JSON.stringify(comp),
      uid,
      releasing_tank: this.state.releasing_tank,
      rack_num:this.state.rack_num,
      loader:this.state.loader
    };

    //validate
    const {brvs,omcs,bdcs,omc,bdc,brv_num,product_type}=this.state
    const errs = await formValidate(data);
  
     
      if(!brvs.includes(brv_num)){
        errs.push('BRV number does not exist')
      }
      if(!omcs.includes(omc)){
        errs.push('OMC does not exist')
      }
      if(!bdcs.includes(bdc)){
        errs.push('BDC does not exist')
      }
      if(!['AGO','PMS','PMS-95'].includes(product_type)){
        errs.push('Product type does not exist')
      }
     
    

    if (errs&&errs.length === 0) {
      try {
        const post = await httpAxios.post(`/fan-bill`, data);
        if (post) {
          data["record_date"] = date;
          localStorage.setItem("waybill", JSON.stringify(data));
          this.handleLoader(false);

          this.setState({
            brv_num: "",
            product_type: "",
            vcf: 0,
            litre_temp: 0,
            litre_density_at_20: 0,
            fan_num: "",
            coll_order: "",
            bdc: "",
            omc: "",
            destination: "",
            driver: "",
            issuer: "",
            total: 0,
            compartments: [1],
            volumeAt20: "",
            released_1: 0,
            released_2: 0,
            released_3: 0,
            released_4: 0,
            released_5: 0,
            released_6: 0,
            released_7: 0,
            volumeAt20: 0,
            requestStatus: "Record added successfully",
            isSubmitting: false,
            releasing_tank:""
          });
         
          // window.location.href = "/waybill";
        } else {
          this.setState({ errs: ["An error occurred"], isSubmitting: false });
        }
      } catch (error) {
        if (error.response?.status === 403) {
          this.setState({ errs: [error.response.data], isSubmitting: false });
        } else {
          this.setState({ errs: ["An error occurred"], isSubmitting: false });
        }
      }
    } else {
      this.setState({ errs, isSubmitting: false });
    }
  };

  addCompartment = () => {
    let { compartments } = this.state;
    if (compartments.length < 7) {
      let x = (compartments.length + 1).toString();
      compartments.push(x);
      this.setState({ compartments });
    }
  };
  compartmentHandler = (m, n) => {
    if (!isNaN(n)) {
      let {
        released_1,
        released_2,
        released_3,
        released_4,
        released_5,
        released_6,
        released_7,
        volumeAt20,
      } = this.state;
      let t =
        parseFloat(released_1) +
        parseFloat(released_2) +
        parseFloat(released_3) +
        parseFloat(released_4) +
        parseFloat(released_5) +
        parseFloat(released_6) +
        parseFloat(released_7);
      volumeAt20 = parseFloat(t) + parseFloat(n);
      this.setState({ [m]: n, volumeAt20 });
    }
  };
  handleSelect = (m, n) => {
    ////console.log(m, n);
    this.setState({ [m]: n });
  };
  handleInput = (m, n) => {
    // //console.log("...typing", m, n);
    this.setState({ [m]: n });
  };

  onToggleUpdateBDCOMC = (n) => {

    this.setState({ updateBDC_OMC: n,open:true });
  };
  unsetUpdateOMCBDC = () => {
    this.setState({ updateBDC_OMC: "" });
  };
  
  //dynamically calculation for vcf
  ApiCalculateVcf = async () => {
    //chek for both values available
    // //console.log("running %%%%");
    this.setState({ loading: true });
    const { litre_density_at_20, litre_temp } = this.state;
    if (litre_density_at_20 && litre_temp) {
      const post = await Create("/vcf/xlsx/calculator", {
        D13: litre_density_at_20,
        D14: litre_temp,
      });
      if (post) {
        this.setState({ vcf: post.vcf });
      }
    } else {
      // console.info('condition vcf: false')
      this.setState({ loading: false });
    }
    this.setState({ loading: false });
  };
  onDelete = (n) => {
    //console.log(n);
  };
  closeAlert = (n) => {
    const { errs } = this.state;
    let del = errs.filter((err) => err !== n);

    this.setState({ errs: [] });
  };

  onHandleAlert = (bool) => {
    this.setState({ showAlert: bool });
  };
   onHandleSuggest = (e) => {
    const value = e.target.value
    let sugg = []
    let suggList = JSON.parse(localStorage.getItem("suggestions"));
    if (typeof suggList === "object" && suggList) {

      //first check if value exists
      sugg = suggList

      const isExists = (list, val) => {
        if (typeof list === "object" && !list.includes(val)) {
          if (val !== "") {
            sugg.push(val);
          }

        }

        return list;
      };

      isExists(suggList, value);

      //sugg.sugg_sealer.push('gfg')
     
      localStorage.setItem("suggestions", JSON.stringify(sugg));
      this.setState({suggestions:[...this.state.suggestions,value]})
    } else {
      //set initial values
      localStorage.setItem("suggestions", JSON.stringify(sugg));
    }
    
  };
  //modal
  handleOpen = () => {

    this.setState({ open: true })
  };

  handleClose = () => {

    this.setState({ open: false });
  };
showSettingsMenu=()=>{
  this.setState({openSettings:!this.state.openSettings})
}
  render() {
    const user = this.context?.user;
    //console.log(user.depot)
    const {
      updateBDC_OMC,
      errs,
      loader,
      preloader,
      compartments,
      bdcs,
      omcs,
      brvs,
      date,
      requestStatus,
      vcf,
      litre_temp,
      litre_density_at_20,
      brv_num,
      total, 
      suggestions, 
      available_brv_for_update, 
      releasing_tank,openSettings,
      open
    } = this.state;
   // console.log(available_brv_for_update)
    const {
      released_1,
      released_2,
      released_3,
      released_4,
      released_5,
      released_6,
      released_7,
    } = this.state;
    let TOTAL_COMPARTMENT_RELEASED =
      parseFloat(released_1) +
      parseFloat(released_2) +
      parseFloat(released_3) +
      parseFloat(released_4) +
      parseFloat(released_5) +
      parseFloat(released_6) +
      parseFloat(released_7);
    let $vAt20 = parseFloat(TOTAL_COMPARTMENT_RELEASED);
    //let TOTAL_VOLUME_OBS = Math.round(parseFloat($vAt20 / vcf));
    let TOTAL_VOLUME_OBS = total;
    return (
      <div
        style={{ backgroundColor: "", maxWidth: "100%", overflow: "hidden" }}
      >
        <Header />
        <Preloader load={preloader} />

        {/* {errs.length > 0 ? <Error msg={errs} /> : null} */}
        <Box sx={{ position: "fixed", width: 400, right: 0,backgroundColor:'#fff' }}>
         <Collapse in={errs.length >0}>
          {errs.length > 0 ? (
            <>
              {errs.map((err, i) => (
                <Alert
                  onClose={this.closeAlert.bind(this, err)}
                  key={i}
                  variant="filled"
                  style={{ position: "relative", margin: ".3rem" }}
                  severity="error"
                >
                  {err}
                </Alert>
              ))}
            </>
          ) : null}
          </Collapse>
        </Box>

        <div className="gh6Ty" style={{display:'flex',alignItems:'center',justifyContent:'center'}}>
          <Button
            value="BDC"
            onClick={()=>this.onToggleUpdateBDCOMC('BDC')}
          >
            BDC
          </Button>
          <Button
           
            value="OMC"
            onClick={()=>this.onToggleUpdateBDCOMC('OMC')}
          >
            OMC
          </Button>
          <Button
            value="BRV"
            onClick={()=>this.onToggleUpdateBDCOMC('BRV')}
          >
            BRV
          </Button>
         
             
         <EditForm brvs={this.state.available_brv_for_update}/>
         {
          user&&user.depot===1&&<IconButton onClick={this.showSettingsMenu}>
                     <SettingsOutlinedIcon/>
                   </IconButton>
         }
          <Box>
         
         {user&&user.depot===1&&this.state.openSettings&& 
          <Box style={{width:'160px',height:50,position:'absolute'}}>
                   <Paper>
                   <MenuItem 
                     onClick={
                      ()=>{
                      sessionStorage.setItem('__designation','inter-depot-loading')

                        this.setState({openSettings:!this.state.openSettings})
                        process.env.NODE_ENV==='production'?(window.location.href='https://apd.bostmonitor.com'):(window.location.href='http://localhost:3002')
                      }
                     }>Inter-Depot Loading</MenuItem>
                   <MenuItem
                      disabled 
                       onClick={
                          ()=>{
                            sessionStorage.setItem('__designation','omc-loading')
                            this.setState({openSettings:!this.state.openSettings})
                           }
                        }
                   >OMC Loading</MenuItem>
                   </Paper>
                   
                     </Box>}
          </Box>
        </div>

        {updateBDC_OMC!==""&&
        <Modal open={open} onClose={this.handleClose}>
        <MUIaddForm
          unmount={this.unsetUpdateOMCBDC}
          action={updateBDC_OMC}
          onDelete={this.onDelete}
        />
          </Modal>
        }
        {/*updateBDC_OMC !== "" ? (
          <BDC_OMC
            unmount={this.unsetUpdateOMCBDC}
            action={updateBDC_OMC}
            onDelete={this.onDelete}
          />
        ) : null*/}
        {requestStatus !== null && this.state.showAlert ? (
          <TimeoutHandler showAlert={this.onHandleAlert} />
        ) : null}
        {
          <Grid container justifyContent="center" spacing={2}>
            <Grid item>
              <MuiForm
                ApiCalculateVcf={this.ApiCalculateVcf}
                bdcs={bdcs}
                omcs={omcs}
                brvs={brvs}
                date={date}
                vcf={vcf}
                brv_num={brv_num}
                volumeAt20={$vAt20}
                volumeObs={TOTAL_VOLUME_OBS}
                omc={this.state.omc}
                releasing_tank={this.state.releasing_tank}
                loading={this.state.loading}
                product_type={this.state.product_type}
                bdc={this.state.bdc}
                handleInput={this.handleInput}
                handleSelect={this.handleSelect}
              />
            </Grid>
            <Grid item>
              <Card style={this.EntryFormCard} variant="outlined">
                <CardContent style={{ padding: 0 }}>
                  {compartments.map((c, i) => (
                    <CompartmentX2
                      key={i}
                      keyx={i + 1}
                      type="text"
                      name="compartment"
                      handler={this.compartmentHandler}
                      lbl="COMPARTMENT"
                      suggestions={suggestions}
                      onHandleSuggest={this.onHandleSuggest}
                    />
                  ))}

                  <Grid container alignItems="center">
                    <Grid item>
                      <IconButton
                        onClick={this.addCompartment}
                        disabled={compartments.length === 7}
                      >
                        <Add style={{ fontSize: "2.5rem", color: "#f3b871" }} />
                      </IconButton>
                    </Grid>
                    <Grid item>
                      <FormLabel style={{ fontSize: ".8rem" }}>
                        {compartments.length === 7
                          ? "You've reached the maximum number of compartments"
                          : "Add compartment"}
                      </FormLabel>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        }
        <form onSubmit={this.ONSUBMIT} className="">
          <Box className="yuOp2" style={{}}>
            {/* <button className="submitBtn" type="submit">
          Submit
        </button> */}
            <Tooltip title="Submit">
              <IconButton
                style={{ color: "#fff", backgroundColor: "#008000b8" }}
                className="submitBtn"
                type="submit"
                disabled={this.state.isSubmitting}
              >
                {this.state.isSubmitting ? (
                  <CircularProgress
                    style={{ color: "#fff", height: 30, width: 30 }}
                  />
                ) : (
                  <Send />
                )}
              </IconButton>
            </Tooltip>
          </Box>
        </form>

      </div>
    );
  }
}

const TimeoutHandler = ({ showAlert }) => {
  const navigate =useNavigate()
  //const [run, setRun] = useState(false);
  useEffect(() => {
    const timeout = setTimeout(() => {
     navigate("/app/waybill");
      return showAlert(false);
    }, 2500);
    return () => {
      clearTimeout(timeout);
    };
  }, []);
  const onClose = () => {

    navigate("/app/waybill");
    return showAlert(false);
  };
  return (
    <Alert
      onClose={onClose}
      style={{ position: "relative" }}
      severity="success"
    >
      Entry saved successfully <Link to="/waybill">View</Link>{" "}
    </Alert>
  );
};

function CompartmentX2({ name, lbl, type, keyx, handler ,suggestions,onHandleSuggest}) {




  const onHandleCompartment = (e) => {
    return handler(e.target.name, e.target.value);
  };
  const classes = useStyles();
  return (
    <>
      <Grid
        container
        style={{ width: "100%" }}
        spacing={2}
        justifyContent="space-between"
      >
        <Grid item xs={3}>
          <TextField
            label="COMP."
            type={type}
            value={keyx}
            name={`${name}${keyx}`}
            readOnly
            variant="outlined"
            style={{ width: 60 }}
            size="medium"
          />
        </Grid>
        <Grid item xs={9} style={{ display: "flex", alignItems: "center" }}>
          <TextField
            label="RELEASES@20"
            type={type}
            name={`released_${keyx}`}
            onChange={onHandleCompartment}
            variant="outlined"
            InputLabelProps={{
              shrink: true,
            }}
            style={{ width: "auto" }}
            onBlur={onHandleSuggest}
            size="medium"
            inputProps={{list:`released_${keyx}`}}
          />
        </Grid>

      </Grid>
      <datalist id={`released_${keyx}`}>
             
             {
              suggestions&& suggestions.map((option, i) => (
                 <option key={i} value={option}> {option}</option>
               ))
             }
           </datalist>
    </>
  );
}
function EditForm({brvs}){

 //console.log(brvs)
    return (
      <>
        <SelectBox data={brvs}/>
      </>
      )
  }

 function SelectBox({  data,...rest }) {
  const navigate =useNavigate()
  const [sel, setSel] =React.useState('')
  const gotoRecall=()=>{
    const dt = data.filter((d)=>d.brv_num===sel)
    if(sel !==''&&dt.length > 0){
      sessionStorage.setItem('_recall_brv', JSON.stringify(dt[0]))
     
      navigate({pathname:`/app/recall/${sel}`,state:{data:dt[0]}})
    }
  }
  
    return (
      <>
        <FormControl
          style={{ width: "200px", fontSize: ".8rem",margin:'auto 10px' }}
          fullWidth
          variant="filled"
        >
          <InputLabel id="demo-simple-select-helper-label"> RECALL BRV # </InputLabel>
          <Select
            style={{ width: "100%" }}
            labelId="demo-simple-select-helper-label"
            id="demo-simple-select-helper"
            defaultValue=""
            onChange={(e)=>setSel(e.target.value)}
            name='brvs'
            label='Recall'
            disabled={data.length===0}
            {...rest}
          >
            <MenuItem value="">
              <em>None</em>
            </MenuItem>

            {
              data &&
              data.map((d, i) => (
                <MenuItem value={d.brv_num} key={i} >
                  {d.brv_num}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
        <Button disabled={data.length===0} onClick={gotoRecall}>Go</Button>
      </>
    );
  }