import React, { useState } from 'react';

import {Update} from "../../controllers/"
function ToggleSwitch({bool,email}) {
	const [toggle, setIsToggled] = useState(bool);
	
	////console.log(bool)


	//const onToggle = () => setIsToggled(!toggle);
	return (
		<label className="toggle-switch">
			<input type="checkbox" defaultChecked={bool}  onChange={onUpdate} />
			<span className="switch" />
		</label>
	);
	async function onUpdate(){
		let ver = toggle===0?1:0;
		setIsToggled(ver)
		////console.log(toggle,ver);
		
		 const update = await Update("/user",{verified:ver,email})
		 if(update){
			alert("User verification status updated")
		 }
	}
}
export default ToggleSwitch;
