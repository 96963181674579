import React, { Component, useRef, useState, useEffect } from 'react'
import Header from '../Components/header/';
import { Link, Outlet } from "react-router-dom";
import Users from "./Users/Users";
import ReactToPrint from 'react-to-print';
import Report from "./Report/";
import Preloader from "../Components/preloader/"
import { ReactSVG } from "react-svg"
import { Read } from "../controllers/Read/"
import depotMapper from '../depotMapper'
//-----------------------------------------------------------------
import { Typography } from '@material-ui/core'
class Dashboard extends Component {
	constructor() {
		super()
		this.state = {
			load: true,
		}
	}

	handlePreloader = (n) => {
		//let tg = n===true?false:true;
		////console.log(n," process")
		this.setState({ load: n })
	}
	render() {
		const { load } = this.state;
		const param = this.props;
		
		return (
			<div className="main-pane">
				<Header />
				<Preloader load={load} />
				<section className="pane">
					<SideBar current={param} />
					<div className="main-bost-pg">
					
							<DashboardLanding src={`${process.env.PUBLIC_URL} /assets/icons/`} process={this.handlePreloader} />
						
					</div>
					{/* <MainPane
						src={`${process.env.PUBLIC_URL}/assets/icons/`}
						page={param}
						process={this.handlePreloader}
					/> */}


				</section>
			</div>
		);
	}
}
//export default withRouter(Dashboard)
export default Dashboard




function SideBar({ current }) {
	const curr = { background: '#0606bb12' };
	return (
		<div className="sb-main1">
			<aside className="sidebar-container">
				<div>
					<ul>
						<li>

							<div className={`link-itm`} style={{ height: 70, paddingTop: '1rem' }}>


								<Typography variant='h6'>{depotMapper()} </Typography>
							</div>

						</li>
						<li>
							<Link to="/admin/">
								<div className={`link-itm`} >

								</div>
							</Link>
						</li>
						<li>
							<Link to="/admin/">
								<div className={`link-itm`} style={current === undefined ? curr : null}>
									<img src={`${process.env.PUBLIC_URL}/assets/icons/dashboard.png`} height="25px" alt="" />
									DASHBOARD
								</div>
							</Link>
						</li>
						<li>
							<Link to="/admin/users">
								<div className={`link-itm`} style={current === 'users' ? curr : null}>
									<img src={`${process.env.PUBLIC_URL}/assets/icons/users.png`} height="25px" alt="" />

									USERS
								</div>
							</Link>
						</li>

						<li>
							<Link to="/admin/report">
								<div className="link-itm" style={current === 'report' ? curr : null}>
									<img src={`${process.env.PUBLIC_URL}/assets/icons/data.png`} height="25px" alt="" />

									REPORTS
								</div>
							</Link>
						</li>
					</ul>
				</div>
			</aside>
		</div>
	);
}

function DashboardLanding({ process, src }) {
	const handlePreloader = () => {
		return process(false)
	}
	const [counts, setCounts] = useState({ users: "", fanbill: "" })
	useEffect(() => {


		async function count() {
			const r = await Read('/user/count');
			////console.log(r);
			if (r) {
				setCounts({ users: r.users, fanbill: r.fanbill });
			} else {
			}
		}
		count();
		handlePreloader();
	}, []);
	return (
		<div className="fdoDnY7">
			<div className="dashboard-preview">
				<div className="UIbT1">
					<Link to="/admin/users">
						<h1>
							<img src={src + 'group.png'} alt="users" />
							{counts.users} Users
						</h1>
						<em>Total number of users</em>
					</Link>
				</div>
				<div className="UIbT2">
					<Link to="/admin/report">
						<h1>
							<img src={src + 'data.png'} alt="users" />
							{counts.fanbill} Records
						</h1>
						<em>Total waybill records made</em>
					</Link>
				</div>
			</div>
		</div>
	);
}