import React,{useState} from 'react';
export default function Error({ msg }) {
	const [errors,setErrors]=useState(msg)
	function toggle(){
		msg=[]
		setErrors(msg)
	}
	return (
		<React.Fragment>
			{errors.length > 0?
			<div className="msg-err">
				<button onClick={toggle}>&times;</button>
				{
					msg.map((err,i)=> <div key={i}>{err}</div>)

					}

			</div>: null}
		</React.Fragment>
	);
}
