import React, { useEffect, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom'
import { Grid, styled, Box, Typography, Card, MenuItem, CardContent, Paper } from '@material-ui/core'
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { Read } from '../../controllers';
//--------------------------------------------------------------
const DataBox = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    textDecoration: 'none',
    color: '#fff',
    width: 300,
    minHeight: 150,
    padding: 20,
    margin: 20
})
export default function Landing() {
    const [users, setUsers] = useState([])
    const [entries, setEntries] = useState([])
    const [counts, setCounts] = useState(null)

    useEffect(() => {
        async function fetchCounts() {
            const r = await Read('/user/count');
            if (r) {
                setCounts({ users: r.users, fanbill: r.fanbill });

            }
        }
        async function fetchTodaysEntries() {
            const r = await Read('/fan-bill');
            if (r) {
               
                setEntries(r)
            }
        }
        async function fetchUsers() {
            const u = await Read('/user')
            if (u) {
              
                setUsers(u)
            }
        }
        fetchCounts()
        fetchTodaysEntries();
        fetchUsers()

    }, [])
    return (
        <>
            <Grid container>
                <Grid item>


                    <DataBox component={RouterLink} to='/admin/users' style={{ backgroundColor: 'indigo' }}>
                        <Typography variant='h1'>{counts && counts?.users || '...'}</Typography>
                        <Typography variant='h5'>Users</Typography>
                    </DataBox>
                    <DataBox component={RouterLink} to='/admin/report' style={{ color: 'orangered', backgroundColor: 'orange' }}>
                        <Typography variant='h1'>{counts && counts?.fanbill > 1000 ? '1k+' : counts?.fanbill || '...'}</Typography>
                        <Typography variant='h5'>Records</Typography>
                    </DataBox>
                </Grid>
                <Grid item>
                    <Card variant='outlined' style={{ marginTop: 20 }}>
                        <CardContent>


                            <TableContainer >
                                <Typography variant='h6'>Users</Typography>
                                <Table size='small' variant='outlined'>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Name</TableCell>

                                            <TableCell align="left">Role</TableCell>
                                            {/* <TableCell align="left">Email</TableCell> */}
                                            <TableCell align="left">Contact</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {
                                            users.slice(0, 10).map((row,id) => (
                                                <TableRow key={id}>
                                                    <TableCell>
                                                        <Typography>{row.fullName}</Typography>
                                                    </TableCell>
                                                    <TableCell>
                                                        <Typography >
                                                            {row.level?.toLowerCase()}
                                                        </Typography>
                                                    </TableCell>
                                                  
                                                    <TableCell>
                                                        <Typography>{row.contact || 'N/A'}</Typography>
                                                    </TableCell>
                                                </TableRow>
                                            ))
                                        }

                                        <TableRow>
                                            <TableCell>
                                                <Typography component={RouterLink} to='/admin/users'>View all</Typography>
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>

                        </CardContent>
                    </Card>
                </Grid>
                <Grid item >
                    <Box style={{ marginTop: 20, marginLeft: 20 }}>
                        <Card variant='outlined'>
                            <CardContent>
                                <Typography variant='h6'>Today's Entries</Typography>

                                <TableContainer >
                                    <Table size='small'>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell><Typography>BRV</Typography></TableCell>
                                                <TableCell><Typography>Product Type</Typography></TableCell>

                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {
                                                entries.slice(0, 10).map(row => (
                                                    <TableRow key={row.id}>
                                                        <TableCell>
                                                            <Typography>{row.brv_num}</Typography>
                                                        </TableCell>
                                                        <TableCell>
                                                            <Typography>{row.product_type}</Typography>
                                                        </TableCell>
                                                    </TableRow>
                                                ))
                                            }
                                            <TableRow>
                                                <TableCell>
                                                    <Typography component={RouterLink} to='/admin/report'>View all</Typography>
                                                </TableCell>
                                            </TableRow>
                                        </TableBody>
                                      
                                    </Table>
                                </TableContainer>
                            </CardContent>
                        </Card>

                    </Box>
                </Grid>
            </Grid>
        </>

    )
}
