import { useRoutes, Navigate } from 'react-router-dom';
import React from 'react';
import Dashboard from "./Admin/index";
import DashboardApp from './Admin/Dashboard'
import Create from "./Admin/Users/create";
import Users from './Admin/Users/Users'
import Report from "./Admin/Report/";
import ReactPrint from './Admin/ReactPrint'
import { useRef } from 'react'
import redirect from './redirects'
import RoleGuard from './Components/RoleGuard'
//Entry routes components
import Form from "./Components/form";
import PrintWaybill from "./Components/PrintWaybill";
import RecallForm from './Components/form/recall';
import Landing from './Admin/Dashboard/Landing'
//------------------Authentication----------------------------------------------------
import { useAuth } from './lib/use-auth';
import { AdminRoleGuard, EntryRoleGuard, FallbackGuard } from './Components/Guard';
//-----------------------------------------------------------------
export default function AppRouter() {

  const pdfRef = useRef();
  const process = () => { };

  //perform authentication and privilage redirection role
  return useRoutes([
    { path: '/', element: <FallbackGuard /> },
    {
      path: '/app', element: <EntryRoleGuard />,
      children: [
        { path: '', element: <EntryRoleGuard><Form /></EntryRoleGuard> },

        { path: 'entry', element: <EntryRoleGuard><Form /></EntryRoleGuard> },
        { path: 'recall/:brv', element: <EntryRoleGuard><RecallForm /> </EntryRoleGuard> },
        { path: 'waybill', element: <EntryRoleGuard><PrintWaybill /></EntryRoleGuard> },
      ]
    }
    ,
    {
      path: '/admin', element: <AdminRoleGuard><DashboardApp /></AdminRoleGuard>,
      children: [
        { path: '', element: <AdminRoleGuard><Landing /></AdminRoleGuard> },

        { path: 'users', element: <AdminRoleGuard><Users process={process} /></AdminRoleGuard> },
        { path: 'users/create', element: <AdminRoleGuard><Create process={process} /></AdminRoleGuard> },

        { path: 'report', element: <AdminRoleGuard><Report process={process} /></AdminRoleGuard> },
        { path: 'fan-bill', element: <AdminRoleGuard><ReactPrint ref={pdfRef} process={process} /></AdminRoleGuard> }

      ]
    },

    { path: '*', element: <Navigate to='/' replace /> },
  ]);

}
