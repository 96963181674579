import React, { Component } from 'react';
import Header from "../header/";
import ReactToPrint from "react-to-print";
import Waybill from "../Waybill";
import Preloader from "../preloader/";
import ConfirmWindow from "../prompto/";
import {userDetail} from "../../Auth/"
import {DeleteOne} from "../../controllers/Remove/DeleteOne"
import {Read} from "../../controllers/Read/"
import {useAuth,authContext} from "../../lib/use-auth"
//-----------------------------------------------
export default class PrintWaybill extends Component {
	static contextType = authContext
	constructor(){
		super()

		this.state={
			load:true,
			del: false,
			doc:"",
			userId:0,
			empty:false
		}
	}
	componentDidMount=async()=>{
		//console.log(this.context?.user)
		const user =this.context?.user;
		const get = await Read(`/fan-bill/print/${user.uid}`);
		
		if(get && get.length >0){
			this.setState({doc:get[0].id})
			
		}else{
			this.setState({empty: true})
		}
		if(user){
			this.setState({userId:user.uid})
		}
	}
	handlePreloader=(n)=>{

		this.setState({load:n})
	}
	handleDeleteDialogue=async(n)=>{
		const {doc,userId}=this.state
		if(n){
			const deleteWaybill = await DeleteOne(`/fan-bill/remove/${doc}/${userId}`)
			if(deleteWaybill){
				this.setState({del: false})
				window.location.href="/"
			}else{
				this.setState({del: false})
			}
		}else{
				this.setState({del: false})
			}
	}
	handleEmptyDialogue=()=>{
		this.props.history.push('/')
	}
	deleteWaybill=()=>{
		this.setState({del: true})
	}
	render() {
		const  {load,del,doc,userId,empty}=this.state
		return (
			<div className="TjkG3">
                <Header/>
                <Preloader load={load}/>
                 {empty===true?<ConfirmWindow action={this.handleEmptyDialogue} msg={"Sorry, you don't have a waybill record"}/>:null}
                {

                doc !== ""&& userId !== 0?
                <div>
               
                {del?<ConfirmWindow action={this.handleDeleteDialogue} msg={"Please confirm to delete waybill"}/>:null}
                	
               	{/* <button  onClick={this.deleteWaybill} type="button" className="delete-waybill-btn">&times;</button> */}
				</div> :null}
				<ReactToPrint
					trigger={() => {
						return (
							<div className="yuOp2" style={{width:'90vw'}}>
								{
									 doc !== ""&& userId !== 0?
									
								<button className="submitBtn" type="submit">
									PRINT
								</button>: null}
							</div>
						); 
					}}
					content={() => this.componentRef}
				/>
				<div className="wrapForPrint">
				<Waybill process={this.handlePreloader} ref={el => (this.componentRef = el)} />
			</div>
			</div>
		);
	}
}

