import React, { Component } from "react";
import { Create, Read } from "../../controllers/";
import Close from "@material-ui/icons/Close";
import Delete from "@material-ui/icons/Delete";
import {
 
    Box,
    IconButton,
    Button,
    LinearProgress,
    Collapse, Paper, MenuItem, Typography, TextField, CircularProgress
} from "@material-ui/core";
import { DeleteOne } from "../../controllers/Remove/DeleteOne";
import { Alert } from "@material-ui/lab";
class MUIaddForm extends Component {
    constructor() {
        super();
        this.bdcRef = React.createRef()
        this.state = {
            action: "",
            bdc: "",
            omc: "",
            brv: "",
            err: [],
            data: [],
            response: { type: "", message: "" },
            requesting: false,
            open: false,
        };
    }
    componentDidMount = async () => {
       
       
        const { action } = this.props;
        let data = [];
        data = await Read(`/${action?.toLowerCase()}`);
        if (data) {
            let c = data.map((d) => d.name);
            //  //console.log(c);
            this.setState({ data: c });
        } else {
            this.setState({ err: ["Something went wrong"] });
        }
    };
    handleInput = (n) => {
        //  console.log(m,n.target.value)
        this.setState({ [n.target.name]: n.target.value });
       
    };
    UPDATE_BDC = async (e) => {
        const { data, bdc } = this.state;
        e.preventDefault();
        if (bdc === "") {
            this.setState({ err: ["BDC name is required"] });
        } else {
            //send request
            const add = await Create("/bdc/add", { name: bdc.toUpperCase() });
            if (add) {
                data.unshift(bdc.toUpperCase());
                this.setState({
                    bdc: "",
                    data: [...data, bdc.toUpperCase()],
                    response: { type: "success", message: "BDC added successfully" },
                });
            } else {
                this.setState({
                    response: {
                        type: "error",
                        message: "Could not add BDC, try again !",
                    },
                });
            }
        }
    };
    UPDATE_OMC = async (e) => {
        const { data, omc } = this.state;
        e.preventDefault();
        if (omc === "") {
            this.setState({ err: ["OMC name is required"] });
        } else {
            //send request
            data.unshift(omc.toUpperCase());
            const add = await Create("/omc/add", { name: omc.toUpperCase() });
            if (add) {
                console.log(document.getElementById('omc').value)
                this.setState({
                    omc: "",
                    data: [...data, omc.toUpperCase()],
                    response: { type: "success", message: "OMC added successfully" },
                });
            } else {
                this.setState({
                    response: {
                        type: "error",
                        message: "Could not add OMC, try again !",
                    },
                });
            }
        }
    };
    UPDATE_BRV = async (e) => {
        e.preventDefault();
        const { data, brv } = this.state;
        if (brv === "") {
            this.setState({ err: ["BRV name is required"] });
        } else {
            //send request
            const add = await Create("/brv/add", { name: brv.toUpperCase() });
            if (add) {
                const n = data.unshift(brv.toUpperCase());
                this.setState({
                    brv: "",
                    data: [...data, brv.toUpperCase()],
                    response: { type: "success", message: "BRV added successfully" },
                });
            } else {
                this.setState({
                    requesting: false,
                    response: {
                        type: "error",
                        message: "Could not add BRV, try again !",
                    },
                });
            }
        }
    };
    onDelete = async (n) => {
        ////console.log(n,this.state.action);

        this.setState({ requesting: true });
        const data = this.state.data;
        const item = data[n];

        const del = await DeleteOne(`/${this.props.action}/${encodeURI(item)}`);
        if (del) {
            data.splice(n, 1);
            this.setState({
                requesting: false,
                data,
                response: { type: "success", message: item + " has been deleted !" },
            });
        } else {
            this.setState({
                requesting: false,
                response: { type: "error", message: "Could not delete " + item + " !" },
            });
        }
    };
    closeAlert = () => {
        this.setState({ response: { type: "", message: "" } });
    };

    render() {
        const { open, err, data, response, requesting } = this.state;

        const { action, unmount } = this.props;

        return (
            <>


                <Box style={{ width: '100%', height: '100vh', margin: 'auto', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                   

                    <Paper style={{ height: '80vh', width: 500, padding: '1.5rem',overflow:'hidden' }}>

                        <form className="confirm" autoComplete="off">
                            {
                              err.length>0  &&<Alert severity='error'>
                                  {err.map(e=>(
                                      <Typography>{e}</Typography>
                                  ))}
                                  </Alert>
                            }
                            {response.message !== "" ? (
                               
                                    <Alert
                                        action={
                                            <Button
                                                color="inherit"
                                                size="small"
                                                onClick={this.closeAlert}
                                                style={{
                                                    fontSize: ".7rem",
                                                    width: 20,
                                                    height: 20,
                                                    textTransform: "none",
                                                }}
                                            >
                                                Hide
                                            </Button>
                                        }
                                        severity={response.type}
                                        style={{
                                            width: 'auto',
                                            position: "relative",
                                            fontSize: ".8rem",
                                        }}
                                    >
                                        {response.message}
                                    </Alert>
                               
                            ) : null}
                            {requesting ? <LinearProgress /> : null}

                            <Box
                                sx={{
                                    marginTop: "-20px",
                                    position: "absolute",
                                    width: "calc(500px - 3rem)",
                                    display: "flex",
                                    justifyContent: "flex-end",
                                }}
                            >
                                <IconButton
                                    style={{
                                        marginRight: "-20px",
                                        position: "relative",
                                        backgroundColor: "#fff",
                                        width: 35,
                                        height: 35,
                                        marginTop: -30
                                    }}
                                    onClick={() => {
                                        return unmount();
                                    }}
                                >
                                    <Close size="large" />
                                </IconButton>
                            </Box>
                            <div className="fm-main-field">
                                {action === "BDC" ? (
                                    <div className="" style={{ display: 'flex' }}>
                                        <TextField
                                            type="text"
                                            variant='outlined'
                                            onChange={this.handleInput}
                                            label="BDC"
                                            name="bdc"
                                          
                                            value={this.state.bdc}
                                            fullWidth
                                        />

                                        <button
                                            type="submit"
                                            className="submitBtn"
                                            style={{ height: 55, margin: '0 10px' }}
                                            onClick={this.UPDATE_BDC}
                                        >
                                            ADD
                                        </button>
                                    </div>
                                ) : null}
                                {action === "OMC" ? (
                                    <div className="" style={{ display: 'flex' }}>
                                        <TextField
                                            variant='outlined'
                                            type="text"
                                            onChange={this.handleInput}
                                            label="OMC"
                                            name="omc"
                                            id='omc'
                                            value={this.state.omc}
                                            fullWidth
                                        />
                                        <button
                                            type="submit"
                                            className="submitBtn"
                                            style={{ height: 55, margin: '0 10px' }}
                                            onClick={this.UPDATE_OMC}
                                        >
                                            ADD
                                        </button>
                                    </div>
                                ) : null}
                                {action === "BRV" ? (
                                    <div className="" style={{ display: 'flex' }}>
                                        <TextField
                                            variant='outlined'
                                            type="text"
                                            onChange={this.handleInput}
                                            label="BRV"
                                            name="brv"
                                            value={this.state.brv}
                                            fullWidth
                                        />
                                        <button
                                            type="submit"
                                            className="submitBtn"
                                            style={{ height: 55, margin: '0 10px' }}
                                            onClick={this.UPDATE_BRV}
                                        >
                                            ADD
                                        </button>
                                    </div>
                                ) : null}
                            </div>

                            <ListData data={data} onDelete={this.onDelete} type={action} />
                        </form>
                    </Paper>

                </Box>


            </>
        );
    }
}

//export default withRouter(BDC_OMC);
export default MUIaddForm;

function ListData({ data, onDelete }) {
    const deleteItem = (index) => {
        return onDelete(index);
    };
    return (
        <>
       
            
            <div className="erTf" style={{ marginTop: '2rem',height:'calc(80vh - 3rem)' }}>
                {data?.length === 0 ? <CircularProgress /> : null}
                {data.map((d, i) => (
                    <MenuItem
                        key={i}
                        style={{ borderBottom: '1px solid #ddd' }}

                    >
                        <Box
                            style={{ width: '100%', display: "flex", justifyContent: "space-between" }}
                        >
                            <div>
                                <Typography variant='body1'>
                                    {d}
                                </Typography>

                            </div>

                            <IconButton
                                onClick={deleteItem.bind(this, i)}
                                style={{
                                    border: 0,
                                    height: 30,
                                    width: 30,
                                    padding: 5,
                                    marginRight: 10,
                                }}
                            >
                                <Delete style={{ color: "red" }} />
                            </IconButton>
                        </Box>
                    </MenuItem>
                ))}


            </div>
        </>
    );
}
function Input({ name, lbl, type, handler, ...rest }) {
    function inputHandler(e) {
        return handler(e.target.name, e.target.value);
    }
    return (
        <div className="fieldEl">
            <div>
                <label>{lbl}</label>
            </div>
            <input type={type} name={name} onChange={inputHandler} {...rest} />
        </div>
    );
}
