import React, { Component } from "react";
import { Read } from "../controllers/";
import { userDetail } from "../Auth/";
import Error from "./Error";
import { fDate } from "../Helpers/Dates";
import { Grid, } from "@material-ui/core";
import {authContext} from '../lib/use-auth'
//--------------------------------------------------
export default class Waybill extends Component {
  static contextType = authContext;
  constructor() {
    super();
    this.state = {
      data: [],
      compartments: [],
      compartmentsReleased: [],
      product: "",
      total: 0,
      lTemp: 0,
      time: null,
      destination: "",
      volAt20: 0,
      driver: "",
      date: null,
      issuer: "",
      vcf: 0,
      lDensity: 0,
      omc: "",
      bdc: "",
      errs: [],
    };
  }
  componentDidMount = async () => {
    const uid = this.context?.user;

    if (uid) {
      const get = await Read(`/fan-bill/print/${uid.uid}`);
      let releaseVals = [];
      let total = 0;
      let date = null;
      if (get.length > 0) {
        const val = JSON.parse(get[0].compartmentReleased);
        //const released = v.map((r)=>)

        for (let v of Object.values(val)) {
          if (v !== 0) {
            //total += parseFloat(v);
            releaseVals.push(v);
          }
        }

        date = get[0].record_date;
        //console.log(date);
        const dateTime = fDate(date);
        this.setState({
          data: [get[0]],
          compartments: JSON.parse(get[0].compartments),
          compartmentsReleased: releaseVals,
          product: get[0].product_type,
          total: get[0].total,
          date: dateTime,
          volAt20: get[0].volumeAt20,
          driver: get[0].driver,
          lTemp: get[0].litre_temp,
          issuer: get[0].issuer,
          destination: get[0].destination,
          time: dateTime.time,
          vcf: get[0].vcf,
          lDensity: get[0].litre_density_at_20,
          omc: get[0].omc,
          bdc: get[0].bdc,
          fan_num: get[0].fan_num,
          brv: get[0].brv_num,
          coll_order: get[0].coll_order,
        });
        this.handleLoader(false);
      } else {
        this.handleLoader(false);
        this.setState({ errs: ["An error occurred"] });
      }
    } else {
    
      this.handleLoader(false);
      this.setState({ errs: ["An error occurred"] });
    }
  };
  handleLoader = (n) => {
    const { process } = this.props;
    return process(n);
  };
  render() {
    const {
      errs,
      coll_order,
      fan_num,
      brv,
      data,
      compartmentsReleased,
      vcf,
      product,
      lDensity,
      date,
      total,
      issuer,
      lTemp,
      destination,
      volAt20,
      driver,
      bdc,
      omc,
      product_type,
    } = this.state;
    return (
      // <div className="pdf-template" style={{backgroundImage:`url(/waybill.jpeg)`,backgroundRepeat:'no-repeat',backgroundSize:'cover'}}>
      <div className="pdf-template" style={{backgroundColor:'#fff',paddingTop:'6%'}}>
      
        <div className="pdf-content-wrapper" style={{marginTop:'50px',paddingTop:'30px'}}>
          {errs.length > 0 ? <Error msg={errs} /> : null}

          <div className="pdf-headers" style={{ margin: 0 }}>
            <div className="pdf-headers-chd">
              <div className="hdr-bdc-omc">
                <h3
                  style={{
                    fontSize: "1.5rem",
                    fontFamily: "italic",
                    fontWeight: 600,
                    lineHeight:1
                  }}
                >
                  <div>{omc}</div>
                 {bdc!==""? <div>({bdc})</div>:null}
                </h3>
              </div>
              <div className="header-fan-brv" style={{ fontSize: "1.2rem",fontWeight:'bold' }}>
                <div style={{ visibility: "hidden", fontSize: "1.2rem" }}>
                  {fan_num}
                </div>
                <div style={{ }}>{brv}</div>
              </div>
            </div>
            <div className="coll_order_date" style={{ fontSize: "1.3rem",fontWeight:'bold',paddingRight:5 }}>
              <div className='div1' style={{width:'60% !important'}}>{fan_num}</div>
              <div className='div2'>{date}</div>
            </div>
          </div>
          {data.map((d, i) => (
            <table
              key={i}
              width="100%"
              className="pdf-tbl"
              style={{ fontSize: "1.3rem",marginTop:50 }}
            >
              <tbody className="top-tbody-info">
                <tr style={{visibility:''}}>
                  <td>
                    L/TEMPRATURE : <strong>{lTemp}</strong> °C
                  </td>
                </tr>
                <tr>
                  <td>
                    L/DENSITY@20 : <strong>{lDensity} (g/ml)</strong>
                  </td>
                </tr>
                   <tr>
                  <td>
                    DRIVER'S NAME : <strong>{driver.toUpperCase()}</strong>
                  </td>
                </tr>

              </tbody>
              <tbody className="btm-tbody">
               

               
             
               
                <tr height="" style={{}}>
                  <td className="pos-right" style={{ textAlign: "left" }}>
                    VOLUME OBS. {product} : <b>{total}Lts.</b>
                  </td>
                </tr>
                  <tr>
                  <td>
                    VOL@20°C : <strong>{volAt20} (Lts)</strong>
                  </td>
                </tr>
                 <tr  style={{visibility:'hidden'}}>
                  <td>
                    VCF : <strong>{vcf}</strong>
                  </td>
                </tr>
              </tbody>
            </table>
          ))}
        </div>
        <table
          width="100%"
          className="pdf-tbl"
          style={{ fontSize: "1.3rem", marginTop: "0", minHeight: "300px" }}
        >
          <tbody className="top-tbody" style={{ width: "100%" }}>
            <tr>
              <th className="yuYio0">
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <span>COMPARTMENT</span>
                  </Grid>
                  <Grid item xs={6}>
                    <span>RELEASED@20 °C</span>
                  </Grid>
                </Grid>
              </th>
            </tr>
            <tr>
              <td style={{padding:0}}>
                {compartmentsReleased.map((c, i) => (
                  <>
                    <div key={i} className="sdUtd3">
                      <span className="pdf-td">
                        {i + 1} &nbsp; {product}
                      </span>
                      <span className="pdf-td">{c}Lts.</span>
                    </div>
                  </>
                ))}
              </td>
              <td></td>
            </tr>
          </tbody>
        </table>

        <Grid
          container
          style={{ marginTop: "2%", fontSize: "1.3rem" }}
          justifyContent="center"
        >
          <Grid item xs={12}>
            <div className="destination-box">
              <div
                colSpan="5"
                style={{ display: "hidden", fontSize: "1.5rem" }}
              >
                {" "}
                {data.length > 0 ? "" : null}{" "}
                <strong>{destination.toUpperCase()}</strong>
              </div>
            </div>
          </Grid>
          <Grid item xs={3} style={{ textAlign: "center",padding:10 }}>
            ISSUER
            <br />
            <p style={{ marginTop: 10 }}>
              <strong>{issuer.toUpperCase()}</strong>
            </p>
          </Grid>
          <Grid item xs={3} style={{ textAlign: "center" }}>
            CUSTOMS
          </Grid>

          <Grid item xs={3} style={{ textAlign: "center" }}>
            BOST
          </Grid>

          <Grid item xs={3} style={{ textAlign: "center" }}>
            CARRIER
          </Grid>
        </Grid>
      </div>
    );
  }
}
