const depotMapper = () => {
    const url = window.location.href;
    // const urls = ['accraomc', 'kumasiomc', 'buipeomc', 'bolgaomc'];

    let d = url.split('/')[2].split('.')[0]
    let label = ''
    if (d === 'accraomc') {
        label = 'APD'
        label='APD'
    } else if (d === 'kumasiomc') {
        label='KUMASI'
    } else if (d === 'buipeomc') {
        label='BUIPE'
    } else if (d === 'bolgaomc') {
        label='BOLGA'
    }else{
        label='Depot'
    }
    return label
}
export default depotMapper