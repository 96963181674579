import React from 'react'
import { Grid, Box, styled, MenuItem, Typography } from '@material-ui/core'
import {Link as RouterLink} from 'react-router-dom'
import DashboardIcon from '@material-ui/icons/Dashboard'
import PeopleAltIcon from '@material-ui/icons/PeopleAlt';
import FolderIcon from '@material-ui/icons/Folder';

//--------------------------------------------------
const Wrapper = styled(Box)({
    height: '100vh',
    width: 240,
    backgroundColor: '#0e213a',

})
const MenuTypogrphy=styled(Typography)({
    fontWeight:600,
    fontSize:'.95rem',
    marginLeft:10,
    textTransform:'capitalize',
    fontFamily:'Montserrat',
    color:'#fff',
    margin:8

})
export default function Sidebar() {
    return (
        <>
            <Wrapper>
                <Grid container spacing={2}>
                    <Grid item xs={12} style={{ height: 100 }}>


                    </Grid>
                    <Grid item xs={12}>
                        <MenuItem component={RouterLink} to='/admin/'>
                            <DashboardIcon style={{color:'grey'}}/>
                            <MenuTypogrphy variant='body2'> Dashboard</MenuTypogrphy>
                        </MenuItem>
                        <MenuItem  component={RouterLink} to='/admin/users'>
                            <PeopleAltIcon style={{ color: 'grey' }}/>
                            <MenuTypogrphy>Users</MenuTypogrphy>
                        </MenuItem>
                        <MenuItem  component={RouterLink} to='/admin/report'>
                            <FolderIcon style={{ color: 'grey' }}/>
                            <MenuTypogrphy>Reports</MenuTypogrphy>
                        </MenuItem>

                    </Grid>

                </Grid>
            </Wrapper>
        </>
    )
}
