import React from 'react'
import ReactToPrint from 'react-to-print';
import Waybill from '../Components/Waybill';

//===================================================
export default function ReactPrint() {
    return (
        <div>
            <ReactToPrint
                trigger={() => {
                    return <button>Print</button>;
                }}
                content={() => this.componentRef}
            />
            <Waybill process={this.props.process} ref={el => (this.componentRef = el)} />
        </div>
    );
}
