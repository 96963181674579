import React from 'react'
import { useLocation, Outlet, Navigate } from 'react-router-dom'
import { useAuth } from '../../lib/use-auth';
//----------------------------------------------------
export default function AdminRoleGuard({ children }) {
  const location = useLocation()
  const { user } = useAuth();
  //console.log(user, 'guard')
  return (
    <>
   

   { 
    user && user.level === 'admin'&&user.designation!==1&&
    <>
      {
        user && user.level === 'admin' && children 
      }
    </>
  }
      
  {
      user && user.level === 'admin'&&user.designation===1 && platformHandler()
  }
    
    </>
  )
}
const platformHandler=()=>{
    return process.env.NODE_ENV==='production'?(window.location.href='https://apd.bostmonitor.com'):(window.location.href='http://localhost:3002')
}