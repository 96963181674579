import httpAxios from '../../config/axios'
import {logOut} from "../../Auth"
const Read = async (ENDPOINT) => {
 
  try {
    const read = await httpAxios.get(`${ENDPOINT}`);
    if (read) {
     
      return read.data;
    }
    return false;
  } catch (error) {
   
     if(error.response?.status ===401){
      //logout
       logOut();
       // //console.log('401 with error',error.response.data,error)
    }
    return false;
  }
};
const ReadMany = async (ENDPOINT,keys) => {
    try {
      const read = await httpAxios.get(`${ENDPOINT}`, keys);
      if (read) {
        return read;
      }
      return false;
    } catch (error) {
   
      return false;
    }
  };
export {Read,ReadMany}