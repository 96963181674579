import { format, formatDistanceToNow } from 'date-fns';

// ----------------------------------------------------------------------

export function fDate(date) {
	
	const result = format(new Date(date), 'dd-MM-yyyy');

  return result
}

export function fDateTime(date) {
  return format(new Date(date), 'dd MMM yyyy HH:mm');
}

export function fDateTimeSuffix(date) {
  return format(new Date(date), 'dd/MM/yyyy hh:mm p');
}

export function fToNow(date) {
  return formatDistanceToNow(new Date(date), {
    addSuffix: true
  });
}




export const fDatei=(n)=>{
	let date= new Date(n);
	let d; let m; let y;
	let h =makeStruct(date.getHours());
	let min = makeStruct(date.getMinutes());
	//let sec = date.getSeconds();
	const time = `${h}:${min}`;
	
	d =makeStruct(date.getDate());
	m = makeStruct(date.getMonth()+1);
	y = date.getFullYear();
	const fd = `${d}-${m}-${y}`
	
	return {date:fd, time}|| null	
}

function makeStruct(x){
	if(x.toString().length === 1){
		return `0${x}`
	}
	
	return x
}