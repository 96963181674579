//name of collection or table
import httpAxios from '../../config/axios'
import {logOut} from "../../Auth"
const Update = async (ENDPOINT, data) => {
  try {
    const create = await httpAxios.put(`${ENDPOINT}`,data);
    if (create) {
      return true;
    }
    return false;
  } catch (error) {
    //console.log(error);
    if(error.response.status ==="401"){
      //logout
        logOut();
    }
    return false;
  }
};
export default Update