import "./App.css";
import React, { useEffect, useState } from "react";
import "./utils/scss/style.scss";
import "./scss/style.scss";
import { createTheme, CssBaseline, ThemeProvider, Typography, CircularProgress } from "@material-ui/core";
import AppRouter from './router'
import OperationTypePicker from './operationTypePicker'
//---------------------Authentication-------------------------------------------
import { useAuth } from './lib/use-auth'

//-----------------------------------------------------
export default function App() {
  const [isTimeout, setIsTimeout] = useState(false);
  const operationType =sessionStorage.getItem('__designation')
  // const []=useState(false)
  useEffect(() => {
    /* let timer;
     localStorage.removeItem("_expiredTime");
     if (isLoggedIn()) {
       timer = new IdleTimer({
         timeout: 900, //expire after 10 seconds 900
         onTimeout: () => {
           setIsTimeout(true);
         },
         onExpired: () => {
           //do something if expired on load
           setIsTimeout(true);
         },
       });
     }
     return () => {
       timer.cleanUp();
     };*/
  }, []);
  const theme = createTheme({});
  const auth = useAuth()
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />

{
  auth&&
  <>
    
  </>
}
      
      {
       auth&&auth.user?.isLoading && <><CircularProgress /><Typography> please wait ...</Typography></>
      }
     
      {
        auth&&auth.user?.depot===1&&
        <>{
          // !operationType && <OperationTypePicker />
        }
          {
            //operationType &&operationType==='inter-depot-loading'&& oprTypeDispatcher()
          }
        
        </>
      }
      
      {<AppRouter />}
    </ThemeProvider>
  );
}

const oprTypeDispatcher=()=>{
  return process.env.NODE_ENV==='production'?(window.location.href='https://apd.bostmonitor.com'): (window.location.href='http://localhost:3002')
}