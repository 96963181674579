import httpAxios from '../../config/axios'
import {logOut} from "../../Auth"
export const DeleteOne = async (ENDPOINT) => {
  try {
    const deleteOne = await httpAxios.delete(`${ENDPOINT}`);
    if (deleteOne) {
     
      return deleteOne.data;
    }
    return false;
  } catch (error) {
    if(error.response?.status ===401){
      //logout
        logOut();
    }
    return false;
  }
};