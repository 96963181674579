import { IconButton, makeStyles, Box, Grid, TextField, FormControl, Button, InputLabel, Select, MenuItem, Paper, Card, Typography, Divider } from '@material-ui/core'
import Close from '@material-ui/icons/Close'
const useStyles = makeStyles((theme) => ({

}))

export default function MuiFilterForm({ handleClose, omcs, bdcs,brvs, products, handleChange }) {
  // console.log(brvs)
    const onHandleClose = () => {
        return handleClose()
    }
    const onHandleChange = (e) => {
        //console.log(e.target.name, e.target.value);
        return handleChange(e.target.name, e.target.value)
    }
    const classes = useStyles();
    return (

        <Card style={{ width: 500, padding: '3rem', margin: 'auto' }}>
            <IconButton style={{ float: 'right', marginTop: -50, marginRight: -20 }} onClick={onHandleClose}>
                <Close />
            </IconButton>
            <Typography variant='h4' style={{ marginBottom: 30, textAlign: 'center' }}>Filter Report</Typography>


            <Box style={{ width: 'auto' }}>

                <Grid container justifyContent='center' spacing={2} >
                    <Grid item xs={6}>
                        <TextField type='date' variant='outlined' label='Start Date' InputLabelProps={{
                            shrink: true,
                        }} onChange={onHandleChange} name='dateFrom'/>

                    </Grid>
                    <Grid item xs={6}>
                        <TextField type='date' size='medium' variant='outlined' label='End Date' InputLabelProps={{
                            shrink: true,
                        }} onChange={onHandleChange} name='dateTo'/>
                    </Grid>
                    <Grid item xs={6}>
                        <TextField type='text' variant='outlined' label='TRANSACTION #' InputLabelProps={{
                            shrink: true,
                        }} onChange={onHandleChange} name='product_type'/>
                    </Grid>
                    <Grid item xs={6}>
                        <SelectBox data={products} field="PRODUCT TYPE" name="product_type" onChange={onHandleChange} />
                    </Grid>
                    <Grid item xs={6}>
                        <SuggestField field="BDC" data={bdcs} name="bdc" onChange={onHandleChange} />
                    </Grid>
                    <Grid item xs={6}>
                        <SuggestField data={omcs} field="OMC" name="omc" onChange={onHandleChange} />
                    </Grid>
                    <Grid item xs={12}>
                        <SuggestField data={brvs} field="BRV #" name="brv_num" onChange={onHandleChange} />
                    </Grid>
                    <Grid item xs={6}>
                        <Button fullWidth variant='outlined' style={{ visibility:'hidden',height: 55, }}>
                            CLEAR
                        </Button>
                    </Grid>
                    <Grid item xs={6}>
                        <Button type='submit' fullWidth variant='contained' style={{ backgroundColor: '#222B47', color: '#fff', height: 55 }}>
                            FILTER
                        </Button>
                    </Grid>
                </Grid>
               
            </Box>
        </Card>

    )
}

function SelectBox({ name, field, data, handleChange, value, ...rest }) {
    return (
        <>
            <FormControl
                style={{ width: "100%", fontSize: ".8rem" }}
                fullWidth
                variant="filled"
            >
                <InputLabel id="demo-simple-select-helper-label"> {field}</InputLabel>
                <Select
                    style={{ width: "100%" }}
                    labelId="demo-simple-select-helper-label"
                    id="demo-simple-select-helper"
                    defaultValue=""
                    value={value}
                    name={name}
                    label={field}
                    {...rest}
                >
                    <MenuItem value="">
                        <em>None</em>
                    </MenuItem>

                    {data &&
                        data.map((d, i) => (
                            <MenuItem value={d} key={i}>
                                {d}
                            </MenuItem>
                        ))}
                </Select>
            </FormControl>
        </>
    );
}

function SuggestField({ data, name, field, handleChange,...rest}){
    return (
        <Grid item xs={12}>
            <TextField
                InputLabelProps={{
                    shrink: true,
                }}
                variant="outlined"
                type="text"
                label={field}
                name={name}
                onChange={handleChange}
                inputProps={{ list: 'sugg_destination'+name }}
               fullWidth
                {...rest}
            />
            <datalist id={'sugg_destination'+name}>

                {
                    data && data.map((option, i) => (
                        <option key={i} value={option}> {option}</option>
                    ))
                }
            </datalist>
        </Grid>
    )
}