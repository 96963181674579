import React from 'react'
import Sidebar from './Sidebar';
import Header from '../../Components/header';
//-------------------------------------------------
import { Grid, Container, Card,CardContent,styled } from '@material-ui/core'
//--------------------------------------------------

const CardWrapper = styled(Card)({
    margin:10,
    minHeight:'100%',
    minWidth:'100%',
})

export default function Layout({ children }) {
    //header & sidebar & outlet
    return (
        <>
            <Container maxWidth='xl' style={{ padding: 0, minHight: '100vh', backgroundColor: '#f1f1f1', marginTop: 'auto' }}>
                <Grid container >
                    <Grid item xs={12}>
                        <Header />
                    </Grid>
                    {/**sidebar */}
                    <Grid item >
                        <Sidebar />
                    </Grid>
                    <Grid item style={{width:'calc(100vw - 275px)'}}>
                        <CardWrapper variant='outlined'>
                            <CardContent>
                                {children}                                
                            </CardContent>
                        </CardWrapper>
                    </Grid>
                </Grid>
            </Container>

        </>
    )
}
