import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  EntryFormCard: {
    width: 700,
    margin:'auto',
    padding:'2.5rem'
    ,marginTop:'5rem',
    borderRadius:0
  },
  EntryFormContainer: {
      fontSize:'.8rem'
  },
  TextField:{
    
}
}));
