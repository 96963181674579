import React, { Component, useEffect, useState } from "react";
import { Create, Read } from "../../controllers/";
//import Read from "../../controllers"
import Header from "../header/";
//import Error from "../Error/";
import Preloader from "../preloader/";
import formValidate from "./form.validate";
import { userDetail } from "../../Auth/";
import { fDate } from "../../Helpers/Dates";
import httpAxios from "../../config/axios";
import { Alert } from "@material-ui/lab";
import Send from "@material-ui/icons/Send";
import Add from "@material-ui/icons/AddCircle";
import { useNavigate } from "react-router-dom";

import BDC_OMC from "../BDC-OMC/";
import {
  Box,
  Modal,
  Card,
  CardContent,
  CircularProgress,
  Collapse,
  FormLabel,
  Grid,
  IconButton,
  TextField,
  Tooltip,Typography,Button
} from "@material-ui/core";
import MuiEditForm from "./MuiEditForm";
import { useStyles } from "./muistyles";
import { Link } from "react-router-dom";
import { authContext } from '../../lib/use-auth';
import MUIaddForm from "../BDC-OMC/MUIaddForm";

//----------------------------------------------------
export default class RecallForm extends Component {
  static contextType = authContext;
  constructor(props) {
    super(props);
    this.state = {
      updateBDC_OMC: "",
      preloader: true,
      date: null,
      brv_num: "",
      product_type: "",
      vcf: 0,
      litre_temp: 0,
      litre_density_at_20: 0,
      fan_num: "",
      coll_order: "",
      bdc: "",
      omc: "",
      destination: "",
      driver: "",
      issuer: "",
      compartments: [1,2,3,4,5,6,7],
      compQty: 1,
      total: 0,
      released_1: 4000,
      released_2: 0,
      released_3: 0,
      released_4: 0,
      released_5: 0,
      released_6: 0,
      released_7: 0,
      volumeAt20: 0,
      uid: 0,
      email: "",
      omcs: [],
      bdcs: [],
      requestStatus: null,
      errs: [],
      brvs: [],
      isSubmitting: false,
      showAlert: true,
      suggestions:[],
      brv_to_update:[],
      rack_num:'',
      loader:'',
      open:false
    };
  }
  EntryFormCard = {
    width: 370,
    margin: "auto",
    padding: "2.5rem",
    marginTop: "5rem",
    borderRadius: 0,
  };
  componentDidMount = async () => {
    const storage = sessionStorage.getItem('_recall_brv')
    const updateData= JSON.parse(storage)
    
    this.setState({ brv_to_update: updateData })
//recall variables
//const updateData=this.props.history.location.state.data
 
  
//check and get brv detail from db if 60 minutes not elapsed
//const isRecallable= await Read(`/fan-bill/recall/${updateData?.id}`)
const isRecallable=false;
//set to state

const formdata = Object.entries(updateData)
let tmp = {};
 for (let [k, v] of formdata) {
     if (k !== "compartments") {
        tmp[k] = v;
    }
  }

//console.log(tmp,relvals)
this.setState(tmp)
//this.setState({relvals,released_1:1000})



    const user = this.context?.user;
    if (user) {
      this.setState({ uid: user.uid });
    }
    const d = fDate(new Date());
    this.setState({ date: d, preloader: false });

    //fetch omc - bdcs
    const bdcs = await Read("/bdc");
    if (bdcs) {
      let v = bdcs.map((b) => b.name);
      this.setState({ bdcs: v });
    }
    const omcs = await Read("/omc");
    if (omcs) {
      let v = omcs.map((b) => b.name);
      this.setState({ omcs: v });
    }
    const brvs = await Read("/brv");
    if (brvs) {
      let v = brvs.map((b) => b.name);
      this.setState({ brvs: v });
    }
    //SET & fetch SUGGESTIONS
    let suggestions = localStorage.getItem("suggestions");
    if (!suggestions) {
      let sugg = []

      localStorage.setItem("suggestions", JSON.stringify(sugg));
    }
    let suggObj = JSON.parse(localStorage.getItem("suggestions"));
    this.setState({suggestions: suggObj})
  };
  handleTimeout = () => {
    setTimeout(this.setState({ requestStatus: null }), 5000);
  };
  handleLoader = (n) => {
    let tgl = this.state.load === true ? false : true;
    this.setState({ load: tgl });
  };

  ONSUBMIT = async (e) => {
    e.preventDefault();
    this.handleLoader(true);
    this.setState({ errs: [], isSubmitting: true, showAlert: true });
    const { uid } = this.state;
    const comp = {
      released_1: parseFloat(this.state.released_1),
      released_2: parseFloat(this.state.released_2),
      released_3: parseFloat(this.state.released_3),
      released_4: parseFloat(this.state.released_4),
      released_5: parseFloat(this.state.released_5),
      released_6: parseFloat(this.state.released_6),
      released_7: parseFloat(this.state.released_7),
    };
    let totalCompartment = 0;
    for (let v of Object.values(comp)) {
      totalCompartment += v;
    }
    let TOTAL_VOL_OBS = parseFloat(
      Math.round(totalCompartment / this.state.vcf)
    );

    const date = fDate(new Date());

    const data = {
      date: date.date,
      brv_num: this.state.brv_num,
      product_type: this.state.product_type,
      vcf: parseFloat(this.state.vcf),
      litre_temp: parseFloat(this.state.litre_temp),
      litre_density_at_20: parseFloat(this.state.litre_density_at_20),
      fan_num: this.state.fan_num,
      coll_order: this.state.coll_order,
      bdc: this.state.bdc,
      omc: this.state.omc,
      destination: this.state.destination,
      driver: this.state.driver,
      issuer: this.state.issuer,
     // total: TOTAL_VOL_OBS,
     total:this.state.total,
      compartments: this.state.compartments,
      volumeAt20: isNaN(totalCompartment) ? 0 : totalCompartment,
      //	compQty: this.state.compQty,
      released_1: parseFloat(this.state.released_1),
      released_2: parseFloat(this.state.released_2),
      released_3: parseFloat(this.state.released_3),
      released_4: parseFloat(this.state.released_4),
      released_5: parseFloat(this.state.released_5),
      released_6: parseFloat(this.state.released_6),
      released_7: parseFloat(this.state.released_7),
      compartmentReleased: JSON.stringify(comp),
      uid,
      releasing_tank: this.state.releasing_tank,
      rack_num:this.state.rack_num,
      loader:this.state.loader,
    };

    //validate
    const {brvs,omcs,bdcs,omc,bdc,brv_num,product_type}=this.state
    const errs = await formValidate(data);
 //console.log(errs)
      
      if(!brvs.includes(brv_num)){
        errs.push('BRV number does not exist')
      }
      if(!omcs.includes(omc)){
        errs.push('OMC does not exist')
      }
      if(!bdcs.includes(bdc)){
        errs.push('BDC does not exist')
      }
      if(!['AGO','PMS','PMS-95'].includes(product_type)){
        errs.push('Product type does not exist')
      }
    

    if (errs.length === 0) {
      try {
        const post = await httpAxios.put(`/fan-bill/recall/${this.state.brv_to_update?.id}`, data);
        if (post) {
         data["record_date"] = date;
          localStorage.setItem("waybill", JSON.stringify(data));
          this.handleLoader(false);

          this.setState({
            brv_num: "",
            product_type: "",
            vcf: 0,
            litre_temp: 0,
            litre_density_at_20: 0,
            fan_num: "",
            coll_order: "",
            bdc: "",
            omc: "",
            destination: "",
            driver: "",
            issuer: "",
            total: 0,
            compartments: [1],
            volumeAt20: "",
            released_1: 0,
            released_2: 0,
            released_3: 0,
            released_4: 0,
            released_5: 0,
            released_6: 0,
            released_7: 0,
            volumeAt20: 0,
            releasing_tank:"",
            requestStatus: "Record added successfully",
            isSubmitting: false,
            
          });
         
          // window.location.href = "/waybill";
        } else {
          this.setState({ errs: ["An error occurred"], isSubmitting: false });
        }
      } catch (error) {
        if (error.response?.status === 403) {
          this.setState({ errs: [error.response.data], isSubmitting: false });
        } else {
          this.setState({ errs: ["An error occurred"], isSubmitting: false });
        }
      }
    } else {
      this.setState({ errs, isSubmitting: false });
    }
  };

  addCompartment = () => {
    let { compartments } = this.state;
    if (compartments.length < 7) {
      let x = (compartments.length + 1).toString();
      compartments.push(x);
      this.setState({ compartments });
    }
  };
  compartmentHandler = (m, n) => {
    if (!isNaN(n)) {
      let {
        released_1,
        released_2,
        released_3,
        released_4,
        released_5,
        released_6,
        released_7,
        volumeAt20,
      } = this.state;
      let t =
        parseFloat(released_1) +
        parseFloat(released_2) +
        parseFloat(released_3) +
        parseFloat(released_4) +
        parseFloat(released_5) +
        parseFloat(released_6) +
        parseFloat(released_7);
      volumeAt20 = parseFloat(t) + parseFloat(n);
      this.setState({ [m]: n, volumeAt20 });
    }
  };
  handleSelect = (m, n) => {
    ////console.log(m, n);
    this.setState({ [m]: n });
  };
  handleInput = (m, n) => {
    // //console.log("...typing", m, n);
    this.setState({ [m]: n });
  };

  onToggleUpdateBDCOMC = (n) => {
    this.setState({ updateBDC_OMC: n.target.value,open:true });
  };
  unsetUpdateOMCBDC = () => {
    this.setState({ updateBDC_OMC: "" });
  };
  //dynamically calculation for vcf
  ApiCalculateVcf = async () => {
    //chek for both values available
    // //console.log("running %%%%");
    this.setState({ preloader: true });
    const { litre_density_at_20, litre_temp } = this.state;
    if (litre_density_at_20 && litre_temp) {
      const post = await Create("/vcf/xlsx/calculator", {
        D13: litre_density_at_20,
        D14: litre_temp,
      });
      if (post) {
        this.setState({ vcf: post.vcf });
      }
    } else {
      // console.info('condition vcf: false')
      this.setState({ preloader: false });
    }
    this.setState({ preloader: false });
  };
  onDelete = (n) => {
    //console.log(n);
  };
  closeAlert = (n) => {
    const { errs } = this.state;
    let del = errs.filter((err) => err !== n);

    this.setState({ errs: [] });
  };

  onHandleAlert = (bool) => {
    this.setState({ showAlert: bool });
  };
   onHandleSuggest = (e) => {
    const value = e.target.value
    let sugg = []
    let suggList = JSON.parse(localStorage.getItem("suggestions"));
    if (typeof suggList === "object" && suggList) {

      //first check if value exists
      sugg = suggList

      const isExists = (list, val) => {
        if (typeof list === "object" && !list.includes(val)) {
          if (val !== "") {
            sugg.push(val);
          }

        }

        return list;
      };

      isExists(suggList, value);

      //sugg.sugg_sealer.push('gfg')
     
      localStorage.setItem("suggestions", JSON.stringify(sugg));
      this.setState({suggestions:[...this.state.suggestions,value]})
    } else {
      //set initial values
      localStorage.setItem("suggestions", JSON.stringify(sugg));
    }
    
  };
  //modal
  handleOpen = () => {

    this.setState({ open: true })
  };

  handleClose = () => {

    this.setState({ open: false });
  };
  render() {
    const {
      updateBDC_OMC,
      errs,
      loading,
      preloader,
      compartments,
      bdcs,
      omcs,
      brvs,
      date,
      requestStatus,
      vcf,
      litre_temp,
      litre_density_at_20,
      brv_num,
      total,suggestions,open
    } = this.state;
    const {
      released_1,
      released_2,
      released_3,
      released_4,
      released_5,
      released_6,
      released_7,
    } = this.state;
    const release_default = {
        released_1,
      released_2,
      released_3,
      released_4,
      released_5,
      released_6,
      released_7,
    }
    let TOTAL_COMPARTMENT_RELEASED =
      parseFloat(released_1) +
      parseFloat(released_2) +
      parseFloat(released_3) +
      parseFloat(released_4) +
      parseFloat(released_5) +
      parseFloat(released_6) +
      parseFloat(released_7);
    let $vAt20 = parseFloat(TOTAL_COMPARTMENT_RELEASED);
    //let TOTAL_VOLUME_OBS = Math.round(parseFloat($vAt20 / vcf));
    let TOTAL_VOLUME_OBS = total;
   
    return (
      <div
        style={{ backgroundColor: "", maxWidth: "100%", overflow: "hidden" }}
      >
        <Header />
        <Preloader load={preloader} />

        {/* {errs.length > 0 ? <Error msg={errs} /> : null} */}
        <Box sx={{ position: "fixed", width: 400, right: 0,backgroundColor:'#fff' }}>
         <Collapse in={errs.length >0}>
          {errs.length > 0 ? (
            <>
              {errs.map((err, i) => (
                <Alert
                  onClose={this.closeAlert.bind(this, err)}
                  key={i}
                  variant="filled"
                  style={{ position: "relative", margin: ".3rem" }}
                  severity="error"
                >
                  {err}
                </Alert>
              ))}
            </>
          ) : null}
          </Collapse>
        </Box>

        <div className="gh6Ty" style={{display:'flex',padding:'1rem',alignItems:'center',justifyContent:'space-between'}}>
          <Box>
          <button
            className="typEg1"
            style={{cursor:'pointer'}}
            value="BDC"
            onClick={this.onToggleUpdateBDCOMC}
          >
            BDC
          </button>
          <button
            className="typEg1"
            value="OMC"
             style={{cursor:'pointer'}}
            onClick={this.onToggleUpdateBDCOMC}
          >
            OMC
          </button>
          <button
            className="typEg1"
            value="BRV"
             style={{cursor:'pointer'}}
            onClick={this.onToggleUpdateBDCOMC}
          >
            BRV
          </button>
          </Box>
          <Box sx={{display:'flex',}}>
            <Typography>RECALL BRV # </Typography>
            <Typography>{brv_num}</Typography>
          </Box>
       
        </div>
        {updateBDC_OMC !== "" && (
           <Modal open={open} onClose={this.handleClose}>
          <MUIaddForm
            unmount={this.unsetUpdateOMCBDC}
            action={updateBDC_OMC}
            onDelete={this.onDelete}
            /></Modal>
        ) }
        {requestStatus !== null && this.state.showAlert ? (
          <TimeoutHandler showAlert={this.onHandleAlert} />
        ) : null}
        {
          <Grid container justifyContent="center" spacing={2}>
            <Grid item>
              <MuiEditForm
                ApiCalculateVcf={this.ApiCalculateVcf}
                bdcs={bdcs}
                omcs={omcs}
                brvs={brvs}
                date={date}
                vcf={vcf}
                brv_num={brv_num}
                fan_num={this.state.fan_num}
                volumeAt20={$vAt20}
                volumeObs={TOTAL_VOLUME_OBS}
                omc={this.state.omc}
                loading={this.state.preloader}
                litre_temp={litre_temp}
                product_type={this.state.product_type}
                issuer={this.state.issuer}
                releasing_tank={this.state.releasing_tank}
                bdc={this.state.bdc}
                driver={this.state.driver}
                loader={this.state.loader}
                rack_num={this.state.rack_num}
                destination={this.state.destination}
                handleInput={this.handleInput}
                handleSelect={this.handleSelect}
                litre_density_at_20={litre_density_at_20}
              />
            </Grid>
            <Grid item>
              <Card style={this.EntryFormCard} variant="outlined">
                <CardContent style={{ padding: 0 }}>
                  {compartments.map((c, i) => (
                    <CompartmentX2
                      key={i}
                      keyx={i + 1}
                      type="text"
                      name="compartment"
                      handler={this.compartmentHandler}
                      lbl="COMPARTMENT"
                      value={release_default[`released_${i+1}`]}
                      suggestions={suggestions}
                      onHandleSuggest={this.onHandleSuggest}
                    />
                  ))}



                  <Grid container alignItems="center">
                    <Grid item>
                      <IconButton
                        onClick={this.addCompartment}
                        disabled={compartments.length === 7}
                      >
                        <Add style={{ fontSize: "2.5rem", color: "#f3b871" }} />
                      </IconButton>
                    </Grid>
                    <Grid item>
                      <FormLabel style={{ fontSize: ".8rem" }}>
                        {compartments.length === 7
                          ? "You've reached the maximum number of compartments"
                          : "Add compartment"}
                      </FormLabel>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        }
        <form onSubmit={this.ONSUBMIT} className="">
          <Box className="yuOp2" style={{}}>
            {/* <button className="submitBtn" type="submit">
          Submit
        </button> */}
            <Tooltip title="Submit">
              <IconButton
                style={{ color: "#fff", backgroundColor: "#008000b8" }}
                className="submitBtn"
                type="submit"
                disabled={this.state.isSubmitting}
              >
                {this.state.isSubmitting ? (
                  <CircularProgress
                    style={{ color: "#fff", height: 30, width: 30 }}
                  />
                ) : (
                  <Send />
                )}
              </IconButton>
            </Tooltip>
          </Box>
        </form>
      </div>
    );
  }
}

const TimeoutHandler = ({ showAlert }) => {
  const navigate =useNavigate()
  //const [run, setRun] = useState(false);
  useEffect(() => {
    sessionStorage.removeItem('_recall_brv')
    const timeout = setTimeout(() => {
     navigate("/app/waybill", {replace:true});
      return showAlert(false);
    }, 2500);
    return () => {
      clearTimeout(timeout);
    };
  }, []);
  const onClose = () => {
    // //console.log('func -----');

    navigate("/app/waybill", {replace:true});
    return showAlert(false);
  };
  return (
    <Alert
      onClose={onClose}
      style={{ position: "relative" }}
      severity="success"
    >
      Entry saved successfully <Link to="/app/waybill">View</Link>{" "}
    </Alert>
  );
};

function CompartmentX2({ name, lbl, type, keyx, handler ,value,suggestions,onHandleSuggest}) {

  const onHandleCompartment = (e) => {
    return handler(e.target.name, e.target.value);
  };
  const classes = useStyles();
  return (
    <>
      <Grid
        container
        style={{ width: "100%" }}
        spacing={2}
        justifyContent="space-between"
      >
        <Grid item xs={3}>
          <TextField
            label="COMP."
            type={type}
            value={keyx}
            name={`${name}${keyx}`}
            readOnly
            variant="outlined"
            style={{ width: 60 }}
            size="medium"
          />
        </Grid>
        <Grid item xs={9} style={{ display: "flex", alignItems: "center" }}>
          <TextField
            label="RELEASES@20"
            type={type}
            name={`released_${keyx}`}
            onChange={onHandleCompartment}
            variant="outlined"
            InputLabelProps={{
              shrink: true,
            }}
            style={{ width: "auto" }}
            onBlur={onHandleSuggest}
            size="medium"
            inputProps={{list:`released_${keyx}`}}
            value={value}
          />
        </Grid>

      </Grid>
      <datalist id={`released_${keyx}`}>
             
             {
              suggestions&& suggestions.map((option, i) => (
                 <option key={i} value={option}> {option}</option>
               ))
             }
           </datalist>
    </>
  );
}
